import { Box, CircularProgress, Typography } from "@mui/material";
import Card from "../../ui/card";
import formatCurrency from "../../../utils/formatCurrency";
import useDimensions from "../../../hooks/useDimensions";
import useUser from "../../../hooks/useUser";
import getInstructionByPaymentProvider from "../../../utils/getInstructionByPaymentProvider";
import { t } from "i18next";

const LoadingCard = ({ amount }: { amount: number }) => {
  const { isSmall } = useDimensions();
  const maxWidth = isSmall ? "unset" : "38%";
  const { user, billSearch } = useUser();

  const totalAmount =
    Number(amount) +
    Number(user?.serviceCharge || 0) +
    Number(billSearch?.processsingServiceCharge || 0) +
    -Number(amount);

  return (
    <Box sx={{ maxWidth }}>
      <Card
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          padding: isSmall ? 5 : 10,
        }}
      >
        <Box>
          <Box display={"flex"} justifyContent={"center"}>
            <CircularProgress color="primary" title="loading" />
          </Box>
          <Typography
            fontSize={16}
            fontWeight={600}
            textAlign={"center"}
            color={"#0D109F"}
            paddingBottom={3}
          >
            {t("stepper_3.please_wait")}
          </Typography>

          <Typography fontSize={32} fontWeight={600} textAlign={"center"}>
            {t("stepper_3.waiting_payment")}
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{ fontWeight: "400", fontSize: 20, color: "#707070" }}
            paddingBottom={5}
          >
            {t("stepper_3.payment_notif")}
          </Typography>
          <Typography
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
            paddingBottom={5}
          >
            {formatCurrency(totalAmount)}
          </Typography>
          <Typography
            textAlign={"center"}
            fontSize={14}
            fontWeight={400}
            color={"#707070"}
          >
            {getInstructionByPaymentProvider(user?.paymentProvider || "")}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default LoadingCard;
