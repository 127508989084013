import { ReactNode } from "react";
import { Box, BoxProps, Grid } from "@mui/material";
import useDimensions from "../../../hooks/useDimensions";
import Footer from "../footer";

interface ContainerProps {
  children: ReactNode;
  sx?: React.CSSProperties;
  bordered?: boolean;
}

const Container = ({
  children,
  bordered = false,
  sx = {},
  ...rest
}: ContainerProps & BoxProps) => {
  const { isSmall } = useDimensions();

  return (
    <Grid
      container
      justifyContent="center"
      //alignItems="center"
      style={{
        height: isSmall ? "95vh" : "100vh",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={[
          {
            width: isSmall ? "100%" : "80vw",
            height: isSmall ? "unset" : "80vh",
            margin: {
              sm: "1px",
              md: "10px",
              lg: "2vh",
            },

            marginBottom: {
              sm: "1px",
              md: "10px",
              lg: "18vh",
            },
            paddingBottom: "20px",
            backgroundColor: "white",
            overflowX: "hidden",
            zIndex: 10,
          },

          sx,
        ]}
        border={bordered ? "1px solid #BFC0C0" : ""}
        {...rest}
      >
        {children}
      </Box>
      <Footer />
    </Grid>
  );
};

export default Container;
