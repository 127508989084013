import { useQuery } from "@tanstack/react-query";
import fetchApi from "../fetchApi";
import { ProductPackage } from "../../models/Product";

const useQueryTopup = (options?: any) => {
  return useQuery(["topup"], () => fetchApi("topup").get<ProductPackage[]>(), {
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
};

export default useQueryTopup;
