import { Stack, StackProps } from "@mui/material";
import KeyValueText, { IKeyValueText } from "../KeyValueText";

interface Props extends StackProps {
  list: IKeyValueText[];
  valueStyles?: React.CSSProperties;
}

export const StackList = ({ list, valueStyles, ...rest }: Props) => {
  return (
    <Stack data-testid="stacklist-tesd-id" {...rest}>
      {list?.map((item, key) => (
        <KeyValueText key={key} valueStyles={valueStyles} {...item} />
      ))}
    </Stack>
  );
};

export default StackList;
