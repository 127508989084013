import { Backdrop, CircularProgress } from "@mui/material";

const Loading = ({ isLoading = true }: { isLoading?: boolean }) => {
  return (
    <Backdrop
      sx={{
        color: "#0D109F",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
