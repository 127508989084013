import { Typography } from "@mui/material";
import useDimensions from "../hooks/useDimensions";

const Logo = ({ position = "middle" }: { position?: "middle" | "left" }) => {
  const { isSmall } = useDimensions();
  const height = isSmall ? "25px" : "40px";

  return (
    <>
      {position === "middle" ? (
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, textAlign: "center" }}
        >
          <img
            src={"/assets/logo_old.png"}
            alt="Logo"
            style={{ height, width: "auto", marginRight: 60, paddingTop: 5 }}
          />
        </Typography>
      ) : (
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, paddingLeft: { sm: 0, md: 5, lg: 10 } }}
        >
          <img
            src="/assets/logo_old.png"
            alt="Logo"
            style={{ height, width: "auto", borderRadius: 10 }}
          />
        </Typography>
      )}
    </>
  );
};

export default Logo;
