import { createContext } from "react";
import { EnumPaymentProvider } from "../types";
import { ProductPackage } from "../models/Product";
import { PaymentMethod } from "../models/PaymentMethod";

export interface UserType {
  tag: string;
  cardNumber: string;
  accountNumber: string;
  paymentInput: string;
  paymentMethod: EnumPaymentProvider;
  reference: string;
  amount: string;
  productQuantity: number;
  companyPrlUrl: string;
  companyUuId: string;
  xUpdateNonce: string;
  paymentProvider: string;
  payItemId: string;
  customerEmailaddress: string | undefined;
  customerNumber: string | undefined;
  customerPhoneNumber: string | undefined;
  quote?: number;
  tridCashout: string;
  tridService: string;
  paid: boolean;
  activated: boolean;
  selectedPackage: ProductPackage | null;
  serviceTitle: string;
  billService?: {
    serviceNumber?: string;
    customerName?: string;
    amountLocalCur?: number;
    localCur?: string;
    merchant?: string;
    billNumber?: string;
    customerNumber?: string | undefined;
    payItemId?: string;
    payOrder?: number;
    penaltyAmount?: number;
    billType?: string;
    billDueDate?: string;
    serviceid?: string;
  };
  serviceCharge: number;
  paymentMode?: PaymentMethod
}

export interface UserContextInterface {
  user: UserType | null;
  clearData: () => void;
  /* eslint-disable */
  updateUser: (data: any) => void;
  saveUserData: (key: string, data: any) => void;
}

export const userContextDefaults: UserContextInterface = {
  user: {
    tag: "",
    cardNumber: "",
    accountNumber: "",
    paymentInput: "",
    paymentMethod: EnumPaymentProvider.MOMO,
    reference: "",
    amount: "",
    productQuantity: 0,
    companyPrlUrl: "",
    companyUuId: "",
    xUpdateNonce: "",
    quote: 0,
    paymentProvider: "",
    customerEmailaddress: undefined,
    customerNumber: undefined,
    payItemId: "",
    tridCashout: "",
    tridService: "",
    paid: false,
    activated: false,
    selectedPackage: null,
    customerPhoneNumber: "",
    serviceCharge: 0,
    serviceTitle: "",
    paymentMode: {
      accountNumberType: "",
      currency: "XAF",
      inputLabel: [],
      instructions: [],
      max: 0,
      min: 0,
      name: [],
      payItemId: "",
      paymentProviderId: "",
      validationRegex: "",
      customMethods: false,
    }
  },
  clearData: () => {
    //function to clear Data
  },
  updateUser: () => {
    //function to clear Data
  },
  saveUserData: () => {
    //function
  },
};

export const UserContext =
  createContext<UserContextInterface>(userContextDefaults);
