import { Box, Typography } from "@mui/material";
import Container from "../../components/layout/container";
import GoToCanalBtn from "../../shared/goToCanalBtn";
import useDimensions from "../../hooks/useDimensions";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { isSmall, isMedium } = useDimensions();
  const title = "Seamless Online  Payments";
  const { t, i18n: {changeLanguage, language} } = useTranslation();
  const newDescription = t("homepage.description")
  const metaTag = document.querySelector('meta[name="description"]');

  if (metaTag) {
    metaTag.setAttribute("content", newDescription);
  }

  return (
   
      <Container
        justifyContent="center"
        sx={{
          backgroundColor: "white",
          zIndex: 15,
          overflow: "hidden",
          height: "auto",
          width: "auto",
        }}
      >
        {isSmall || !isMedium ? (
          <Box
            paddingTop={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: "center",
                  fontSize: "24px",
                  lineHeight: "30px",
                  fontWeight: "600",
                  color: "#0D109F",
                  paddingY: 3,
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "17px",
                  fontWeight: "400",
                  color: "#111111CC",
                  paddingX: 1,
                  paddingBottom: 2,
                }}
              >
                {newDescription}
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: "center" }}>
                <img
                  src="/assets/mobilcanalpic.png"
                  alt="Logo"
                  style={{ width: "auto" }}
                />
              </Typography>
            </Box>

            <Box sx={{ paddingBottom: 5 }}>
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: "#111111CC",
                  paddingY: 1,
                }}
              >
                {t("homepage.pay_methods_description")}
              </Typography>
              <Typography sx={{ flexGrow: 1, textAlign: "center" }}>
                <img
                  src="/assets/providers.png"
                  alt="Logo"
                  style={{ width: "80%" }}
                />
              </Typography>
            </Box>

            <GoToCanalBtn size="large" />
          </Box>
        ) : (
          <Box>
            <Box
              display={"flex"}
              justifyContent="space-around"
              alignItems="flex-start"
              paddingTop={5}
              sx={{
                width: "100%",
                paddingRight: {
                  lg: 5,
                  md: 50,
                },
              }}
            >
              <Box
                sx={{
                  width: "40%",
                }}
              >
                <Typography
                  sx={{
                    flexGrow: 1,
                    textAlign: "left",
                    fontSize: {
                      md: "34px",
                      lg: "54px",
                    },
                    lineHeight: {
                      md: "40px",
                      lg: "70px",
                    },
                    fontWeight: "600",
                    color: "#0D109F",
                    paddingTop: 2,
                  }}
                >
                  {t("homepage.title")}
                </Typography>
               
                <Typography
                  sx={{
                    flexGrow: 1,
                    textAlign: "left",
                    fontSize: {
                      md: "16px",
                      lg: "20px",
                    },
                    lineHeight: {
                      md: "20px",
                      lg: "35px",
                    },

                    fontWeight: "400",
                    color: "#111111CC",
                    paddingBottom: 4,
                  }}
                >
                  {newDescription}
                </Typography>
                <Box sx={{ paddingBottom: 4 }}>
                  <GoToCanalBtn size="large" />
                </Box>
                <Typography
                  sx={{
                    flexGrow: 1,
                    textAlign: "left",
                    fontSize: "20px",
                    lineHeight: "35px",
                    fontWeight: "400",
                    color: "#111111CC",
                  }}
                >
                  {t("homepage.pay_methods_description")}
                </Typography>
                <Typography
                  sx={{ flexGrow: 1, textAlign: "left", paddingY: 2 }}
                >
                  <img
                    src="/assets/providers.png"
                    alt="Logo"
                    style={{ width: "auto" }}
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "45%",
                }}
              >
                <Typography
                  sx={{
                    flexGrow: 1,
                    textAlign: "right",
                  }}
                >
                  <img
                    src="/assets/canalpic.png"
                    alt="Logo"
                    style={{ width: "auto" }}
                  />
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/assets/trustedpartners.png"
                alt="Logo"
                style={{ width: "auto" }}
              />
            </Box>
          </Box>
        )}
      </Container>
   
  );
};

export default AboutPage;
