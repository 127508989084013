import { useQuery } from "@tanstack/react-query";
import fetchApi from "../fetchApi";
import { Service } from "../../models/Service";

const useQueryService = (options?: any) => {
  return useQuery(["service"], () => fetchApi("service").get<Service[]>(""), {
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
};

export default useQueryService;
