import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { MenuItemProps } from "../../../navigation";

interface MenuProps {
  menu: MenuItemProps;
}

function MenuItem({ menu }: MenuProps) {
  const { name, href, icon: ItemIcon } = menu;

  return (
    <NavLink
      key={name}
      to={href}
      end
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ItemIcon />
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>
    </NavLink>
  );
}

export default MenuItem;
