/**
 * Currency formatter function
 *
 * @param {Number} value - Value to be formatted
 * @param {String} locale
 * @param {String} currency
 * @returns {String} - Formatted value
 */

const formatCurrency = (value = 0, locale = "fr-CM", currency = "XAF") => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  });

  return formatter.format(value);
};

export default formatCurrency;
