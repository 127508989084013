import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryProduct, useQueryTopup } from "../../../api/queries";
import { PRODUCT, TOPUP } from "../../../constants/constant";
import useDimensions from "../../../hooks/useDimensions";
import useRecentContractNumbers from "../../../hooks/useRecentContractNumbers";
import useUser from "../../../hooks/useUser";
import { ProductPackage } from "../../../models/Product";
import { Service } from "../../../models/Service";
import formatCurrency from "../../../utils/formatCurrency";
import CenterContainer from "../../layout/container/centerContainer";
import HeaderCardService from "../../layout/header/headerCardService";
import Button from "../button/customButton";
import SelectFree from "../inputs/selectFree";
import ProductPackageCard from "../productPackageCard";
import { useTranslation } from "react-i18next";

export interface StepperProps {
  service: Service;
  handleNext: () => void;
  selectedPackage: ProductPackage | null;
  setSelectedPackage: React.Dispatch<
    React.SetStateAction<ProductPackage | null>
  >;
}

const StepFormOne = ({
  service,
  handleNext,
  selectedPackage,
  setSelectedPackage,
}: StepperProps) => {
  const [productPackages, setProductPackages] = useState<ProductPackage[]>([]);
  const { isSmall } = useDimensions();
  const { updateUser, user } = useUser();
  const { recentContractNumbers } = useRecentContractNumbers(
    service?.serviceid as string
  );
  const isTopupType = service?.type === TOPUP;
  const isProductType = service?.type === PRODUCT;
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();

  const { data = [] } = useQueryProduct();
  const { data: dataTopup = [] } = useQueryTopup();

  const getProductPackages = isTopupType
    ? dataTopup?.filter((productPackage) => {
        return productPackage?.serviceid === service.serviceid;
      })
    : data?.filter((productPackage) => {
        return productPackage?.serviceid === service.serviceid;
      });

  useEffect(() => {
    setProductPackages(getProductPackages);
  }, [getProductPackages]);

  const onSubmit = (data: any) => {
    updateUser({
      cardNumber: data?.card_number || "",
    });

    handleNext();
  };

  return (
    <CenterContainer>
      <Alert
        sx={{ mx: isSmall ? 1 : 15, mt: isSmall ? 2 : 5, mb: isSmall ? 4 : 0 }}
        severity="info"
      >
        {t("processing_description", { value: service?.title })}
      </Alert>
      {isSmall ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 1,
              marginBottom: 5,
            }}
          >
            <HeaderCardService service={service} />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ mx: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <>
                  <FormControl fullWidth sx={{ paddingBottom: 5 }}>
                    <InputLabel id={"package-name-id"}>
                      {`${service?.title} Package`}
                    </InputLabel>
                    <Select
                      data-testid={"package-name"}
                      id={"package-name-id"}
                      variant="outlined"
                      label={`${service?.title} Package`}
                      fullWidth
                      required
                      value={selectedPackage?.payItemId}
                      onChange={(e) => {
                        setSelectedPackage(
                          productPackages.find(
                            (element) => element.payItemId === e.target.value
                          ) || null
                        );
                        updateUser({
                          selectedPackage:
                            productPackages.find(
                              (element) => element.payItemId === e.target.value
                            ) || null,
                        });
                      }}
                    >
                      {productPackages.map((productItem, key) => (
                        <MenuItem value={productItem.payItemId} key={key}>
                          {productItem.name}
                          {" - "}
                          {formatCurrency(productItem.amountLocalCur)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <SelectFree
                    control={control}
                    rules={{
                      required: t("errors.field_required"),
                      pattern: {
                        value: new RegExp(service?.validationMask || ""),
                        message: isTopupType
                          ? t("errors.invalid_phone_number")
                          : t("errors.invalid_decoder_number"),
                      },
                    }}
                    label={
                      isTopupType ? t("phone_number") : t("decoder_number")
                    }
                    name="card_number"
                    defaultValue={user?.cardNumber}
                    options={recentContractNumbers}
                  />

                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    size="large"
                    //onClick={handleNext}
                  >
                    {t("button.continue")}
                  </Button>
                </>
              </Box>
            </Box>
          </form>
        </>
      ) : (
        <>
          {selectedPackage ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  marginBottom: 5,
                }}
              >
                <HeaderCardService service={service} />
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "45%",
                      maxWidth: "40%",
                    }}
                  >
                    {(isProductType || isTopupType) && (
                      <>
                        <TextField
                          data-testid={"package-name"}
                          id={"package-name-id"}
                          //source={"Decoder Number"}
                          size="medium"
                          disabled={isProductType || isTopupType}
                          sx={{ width: "100%", paddingBottom: 8 }}
                          variant="outlined"
                          label={`${service?.title} Package`}
                          value={selectedPackage.name}
                          required
                        />

                        <TextField
                          data-testid={"amount"}
                          id={"amount-id"}
                          size="medium"
                          disabled={isProductType || isTopupType}
                          sx={{ width: "100%", paddingBottom: 8 }}
                          variant="outlined"
                          label={t("amount") + " (F CFA)"}
                          value={selectedPackage.amountLocalCur}
                          required
                        />

                        <SelectFree
                          control={control}
                          rules={{
                            required: t("errors.field_required"),
                            pattern: {
                              value: new RegExp(service?.validationMask || ""),
                              message: isTopupType
                                ? t("errors.invalid_phone_number")
                                : t("errors.invalid_decoder_number"),
                            },
                          }}
                          label={
                            isTopupType
                              ? t("phone_number")
                              : t("decoder_number")
                          }
                          name="card_number"
                          defaultValue={user?.cardNumber}
                          options={recentContractNumbers}
                        />

                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          size="large"
                          //onClick={handleNext}
                        >
                          {t("button.continue")}
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              </form>
            </>
          ) : (
            <>
              <Box sx={{ width: "100%", textAlign: "center" }} py={5}>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  {t("choose_your")}{" "}
                  {isTopupType ? t("bundle") : t("subscription")}
                </Typography>
                {!isTopupType && (
                  <Typography>
                    {t("choose_your_subscription_description", {
                      value: service?.title,
                    })}
                  </Typography>
                )}
              </Box>
              <Box
                display={isSmall ? "block" : "flex"}
                justifyContent={"flex-start"}
                flexWrap={"wrap"}
                mx={isSmall ? 0 : 10}
                width={"90%"}
                mt={5}
                data-testid="service-grid"
              >
                {productPackages.map((productItem, key) => (
                  <ProductPackageCard
                    productPackage={productItem}
                    key={key}
                    service={service}
                    setSelectedPackage={setSelectedPackage}
                  />
                ))}
              </Box>
            </>
          )}
        </>
      )}
    </CenterContainer>
  );
};

export default StepFormOne;
