import { Box, Grid, Paper, SxProps } from "@mui/material";
import { ReactNode } from "react";
import useDimensions from "../../../hooks/useDimensions";

interface CardProps {
  children: ReactNode;
  sx?: SxProps;
}

const Card = ({ children, sx = {} }: CardProps) => {
  const { isSmall } = useDimensions();
  const padding = isSmall ? "14px 0" : "24px 0px";

  return (
    <Grid item sx={{ padding }}>
      <Paper>
        <Box sx={sx}>{children}</Box>
      </Paper>
    </Grid>
  );
};

export default Card;
