import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import fetchApi from "../../api/fetchApi";
import Container from "../../components/layout/container";
import ErrorCard from "../../components/layout/errorCard";
import ProcessingCard from "../../components/layout/processingCard";
import SuccessCard from "../../components/layout/successCard";
import useDimensions from "../../hooks/useDimensions";
import useUser from "../../hooks/useUser";
import openReportLink from "../../utils/openReportLink";
import Receipt from "../../components/layout/receipt";
import getErrorMessage from "../../utils/getErrorMessage";
import { t } from "i18next";

const StatusPage = () => {
  const { isSmall } = useDimensions();
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");
  const [data, setData] = useState<any>("");
  const { toPDF, targetRef } = usePDF({
    filename: "reciept.pdf",
    method: "save",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const { canalId = "123" } = useParams();
  const { clearData } = useUser();
  const [isApiRunning, setApiRunning] = useState(true);

  const isFinalResut = data?.status === "ERRORED" || data?.status === "SUCCESS";
  const paddingX = isSmall ? 2 : "31%";

  const verifyPtn = () => {
    setIsFetching(true);
    fetchApi(`verifytx?trid=${String(canalId)}`)
      .get<any>()
      .then((e) => {
        if (e?.usrMsg) {
          setError(e?.usrMsg);
        } else {
          setData(e.at(0));
          setApiRunning(!(e.at(0)?.status === "SUCCESS"));
        }
      })
      .catch((err) => setError(err?.usrMsg))
      .finally(() => {
        setIsFetching(false);
        setIsDisplay(true);
      });
  };

  useEffect(() => {
    // Appelez fetchApiData une fois au chargement de la page
    verifyPtn();

    // Démarrez l'intervalle pour les appels API réguliers
    const apiInterval = setInterval(() => {
      if (isApiRunning) {
        verifyPtn();
      } else {
        clearInterval(apiInterval); // Arrêtez la minuterie lorsque isApiRunning est false
      }
    }, 20000);

    return () => clearInterval(apiInterval);
  }, [isApiRunning]);

  return (
    <Container bordered={!isSmall} sx={{ position: "relative", zIndex: 10 }}>
      {error && (
        <Box ml={1} mb={3}>
          <Alert
            severity={"error"}
            variant={"standard"}
            onClose={() => setError("")}
          >
            <AlertTitle sx={{ fontWeight: "bold" }}>{"Error"}</AlertTitle>
            {error}
          </Alert>
        </Box>
      )}
      <Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isFetching && !isDisplay && <ProcessingCard />}

          {data?.status === "SUCCESS" && (
            <SuccessCard
              amount={data?.priceLocalCur}
              accountNumber={data?.ptn}
              text="SUCCESS"
            />
          )}

          {data?.status === "PENDING" && (
            <SuccessCard
              amount={data?.priceLocalCur}
              accountNumber={data?.ptn}
              text="PENDING"
            />
          )}

          {data?.status === "ERRORED" && (
            <ErrorCard
              amount={data?.priceLocalCur}
              accountNumber={data?.ptn}
              errorMessage={getErrorMessage(data?.errorCode)}
            />
          )}
        </Box>
        {isFinalResut && (
          <Box
            sx={{
              alignItems: "center",
              paddingX,
            }}
          >
            <Button
              variant="contained"
              type="submit"
              fullWidth
              size="large"
              sx={{ marginBottom: 2 }}
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate("/");
                clearData();
              }}
            >
              {t("stepper_4.make_other_payment")}
            </Button>

            <Button
              variant="outlined"
              type="submit"
              fullWidth
              size="large"
              sx={{ marginBottom: 2 }}
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={() => toPDF()}
              //onClick={handleNext}
            >
              {t("button.download_reciept")}
            </Button>

            <Button
              variant="outlined"
              type="submit"
              fullWidth
              size="large"
              startIcon={<AnnouncementOutlinedIcon />}
              color="secondary"
              onClick={openReportLink}
            >
              {t("button.report")}
            </Button>
          </Box>
        )}
      </Box>
      {data?.status === "SUCCESS" && (
        <>
          <div
            style={{
              position: "absolute",
              bottom: 10000,
              width: 1500,
              height: 1500,
              zIndex: -10,
            }}
            ref={targetRef}
          >
            <Receipt totalAmount={data?.priceLocalCur} data={data} />
          </div>
        </>
      )}
    </Container>
  );
};

export default StatusPage;
