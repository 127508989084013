import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import useDimensions from "../../../hooks/useDimensions";
import { t } from "i18next";

const StepperNavigation = ({
  handleReset,
  handleGoBack,
  handleNext,
  step,
}: any) => {
  const { isSmall } = useDimensions();

  const { handleSubmit } = useForm();

  const minWidth = isSmall ? "100%" : "150px";
  const marginBottom = isSmall ? 1 : 0;
  const position = isSmall ? "relative" : "relative";

  const onSubmit = (data: any) => {
    handleNext();
  };

  return (
    <Box
      sx={{
        position,
        bottom: 50,
        width: "100%",
      }}
    >
      <Box paddingX={isSmall ? 2 : 10}>
        <Box
          sx={{
            display: isSmall ? "block" : "flex",
            justifyContent: "space-between",
          }}
        >
          {isSmall ? (
            <>
              <Button
                data-testid="previous-button"
                variant="contained"
                size="large"
                sx={{ minWidth, marginBottom }}
                //disabled={disableActionButton}
                onClick={handleSubmit(onSubmit)}
              >
                {step === 3 ? "Initiate payment" : "Proceed"}
              </Button>
              <Button
                data-testid="cancel-button"
                variant="outlined"
                size="large"
                color="error"
                onClick={handleReset}
                sx={{ minWidth, marginBottom }}
                //disabled={disableActionButton}
              >
                {t("button.cancel")}
              </Button>
              <Button
                data-testid="previous-button"
                variant="outlined"
                size="large"
                sx={{
                  marginRight: isSmall ? 0 : 4,
                  minWidth,
                  marginBottom,
                }}
                //disabled={disableActionButton}
                onClick={handleGoBack}
              >
                {t("button.previous")}
              </Button>
            </>
          ) : (
            <>
              <Button
                data-testid="cancel-button"
                variant="outlined"
                size="large"
                color="error"
                onClick={handleReset}
                sx={{ minWidth, marginBottom }}
                //disabled={disableActionButton}
              >
                {t("button.cancel")}
              </Button>
              <Box>
                <Button
                  data-testid="previous-button"
                  variant="outlined"
                  size="large"
                  sx={{
                    marginRight: isSmall ? 0 : 4,
                    minWidth,
                    marginBottom,
                  }}
                  //disabled={disableActionButton}
                  onClick={handleGoBack}
                >
                  {t("button.previous")}
                </Button>
                <Button
                  data-testid="previous-button"
                  variant="contained"
                  size="large"
                  sx={{ minWidth }}
                  //disabled={disableActionButton}
                  onClick={handleSubmit(onSubmit)}
                >
                  {step === 3 ? t("button.initiate_payment") : t("proceed")}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StepperNavigation;
