import { useQuery } from "@tanstack/react-query";
import fetchApi from "../fetchApi";
import { Service } from "../../models/Service";

const useQueryCashout = (options?: any) => {
  return useQuery(["cashout"], () => fetchApi("cashout").get<Service[]>(""), {
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
};

export default useQueryCashout;
