import { CheckCircle } from "@mui/icons-material";
import { Box, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQueryCashout, useQueryService } from "../../../api/queries";
import useDimensions from "../../../hooks/useDimensions";
import { paymentMethods } from "../../../mockapi/getPaymentMethods";
import { PaymentMethod } from "../../../models/PaymentMethod";
import getGIMACPaymentMethod from "../../../utils/getGIMACPaymentMethod";
import Section from "../../layout/section";
import SelectInput from "../inputs/selectInput";
import { t } from "i18next";

const SelectPaymentMethod = ({
  paymentMode,
  setPaymentMode,
  errorMessage,
}: {
  paymentMode: PaymentMethod | undefined;
  setPaymentMode: any;
  errorMessage?: string;
}) => {
  const { isSmall } = useDimensions();
  const [displayCustomInputs, setDisplayCustomInput] = useState(false);
  const [itemProviderId, setItemProviderId] = useState("");
  const { data = [] } = useQueryService();
  const [country, setCountry] = useState("");
  const { data: cashout = [] } = useQueryCashout();
  const providers = paymentMethods;

  const onSelectProvider = (provider: PaymentMethod) => {
    setDisplayCustomInput(Boolean(provider?.customMethods));
    setPaymentMode(provider);
    setCountry("");
    setItemProviderId("");
  };

  const selectCashout = (id: string) => {
    const selectedCashout = cashout.find((item) => item.serviceid === id);
    setPaymentMode(selectedCashout);
  };

  const gimacData =
    displayCustomInputs && data ? getGIMACPaymentMethod(data) : {};
  const countries =
    displayCustomInputs && gimacData ? Object.keys(gimacData) : [];

  return (
    <Section title={t("stepper_2.select_payment_method")}>
      {errorMessage && <Typography color={"error"}>{errorMessage}</Typography>}
      <Box display={"flex"} flexDirection={"row"} pb={isSmall ? 2 : 5}>
        {providers.map((item: PaymentMethod, i: number) => {
          if (item.payItemId !== "0") {
            return (
              <Box
                key={i}
                sx={{
                  marginLeft: i === 0 ? "unset" : 3,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: !isSmall ? "25%" : "40%",
                }}
              >
                <Box
                  onClick={() => onSelectProvider(item)}
                  sx={{
                    width: { xs: "80px", md: "100px" },
                    height: { xs: "60px", md: "70px" },
                    objectFit: "contain",
                    borderRadius: 1,
                    overflow: "hidden",
                    position: "relative",
                    border:
                      paymentMode?.paymentProviderId === item?.paymentProviderId
                        ? "2px solid #06961C"
                        : "2px solid #FFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backdropFilter: "blur(3px)",
                    backgroundColor: "rgba(0,0,10,0.4)",
                  }}
                >
                  <img
                    src={`/assets/${item.paymentProviderId}.png`}
                    width="100%"
                    height="100%"
                    alt="Providers logo"
                  />
                </Box>
                {paymentMode?.paymentProviderId === item?.paymentProviderId ? (
                  <Box sx={{ pt: 1 }}>
                    <CheckCircle color="success" />
                  </Box>
                ) : (
                  <Box sx={{ pt: 2.5 }}>
                    <Typography
                      noWrap
                      sx={{
                        fontSize:
                          paymentMode?.paymentProviderId ===
                          item?.paymentProviderId
                            ? 16
                            : 13,
                        //wordWrap: "break-word",
                        color: errorMessage
                          ? "red"
                          : paymentMode?.paymentProviderId ===
                            item?.paymentProviderId
                          ? "#06961C"
                          : "inherit",
                        fontWeight:
                          paymentMode?.paymentProviderId ===
                          item?.paymentProviderId
                            ? 700
                            : 100,
                      }}
                      textAlign="center"
                    >
                      {item?.name[0].text}
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          }
        })}
      </Box>
      {displayCustomInputs && (
        <>
          <Box pb={isSmall ? 2 : 5}>
            <SelectInput
              id="country-select"
              value={country}
              label="Select your country"
              onChange={(e) => {
                setCountry(e.target.value);
                setItemProviderId("");
              }}
            >
              {countries.map((value) => (
                <MenuItem value={value}>{value}</MenuItem>
              ))}
            </SelectInput>
          </Box>
          {country && (
            <Box pb={isSmall ? 2 : 5}>
              <SelectInput
                id="paymentMethod"
                value={itemProviderId}
                label="Select your paymentMethod"
                onChange={(e) => {
                  setItemProviderId(e.target.value);
                  selectCashout(e.target.value);
                }}
              >
                {gimacData[country].map((value) => (
                  <MenuItem value={value.serviceid}>{value.title}</MenuItem>
                ))}
              </SelectInput>
            </Box>
          )}
        </>
      )}
    </Section>
  );
};

export default SelectPaymentMethod;
