import { Box, ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import i18n from "i18next";
import { useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  useQueryCashout,
  useQueryProduct,
  useQueryService,
} from "../api/queries";
import Header from "../components/layout/header";
import SideNav from "../components/layout/sideNav/";
import theme from "../config/theme";
import { Home, NotFound, Service, StatusPage, VerifyPage } from "../pages";
import { UserProvider } from "../provider/userProvider";
import en from "../i18n/en.json";
import fr from "../i18n/fr.json";
import getSystemLanguage from "../utils/getSystemLanguage";

const useStyles = makeStyles((theme) => ({
  fullScreen: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundImage: "url(./assets/background_large.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

function Nav() {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();

  // Vérifiez si vous êtes sur la page d'accueil
  const isAboutPage = location.pathname === "/about";

  useQueryProduct();
  useQueryCashout();
  useQueryService();

  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: { en, fr },
      lng: getSystemLanguage(),
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });

  useEffect(() => {
    const unsavedChanges =
      location.pathname.includes("/service") ||
      location.pathname.includes("/verify");

    const handleBeforeUnload = (event: any) => {
      // Afficher le message d'avertissement seulement si des données ont été modifiées

      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = ""; // Pour les navigateurs obsolètes
        return "Vous avez des données non sauvegardées. Êtes-vous sûr de vouloir quitter la page?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location]);

  return (
    <div className={classes.fullScreen}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <Box>
            {!isAboutPage && (
              <SideNav
                open={open}
                handleDrawerOpenClose={handleDrawerOpenClose}
              />
            )}
            <div>
              <Header
                handleDrawerOpenClose={handleDrawerOpenClose}
                isAboutPage={isAboutPage}
              />

              <Box>
                <Routes>
                  <Route path="*" element={<NotFound />} />
                  <Route path="/" element={<Home />}>
                    <Route index element={<Home />} />
                  </Route>
                  <Route path="/category/:categoryName" element={<Home />} />
                  <Route path="/service/:id" element={<Service />} />
                  <Route
                    path="/service/:id/:billNumber"
                    element={<Service />}
                  />
                  <Route path="/verify/:id" element={<VerifyPage />} />
                  <Route path="/verify/:id/:canalId" element={<StatusPage />} />
                  <Route path="/contact-us" element={<>contact us</>} />
                  <Route path="/report" element={<>report</>} />
                  <Route path="/invite" element={<>invite</>} />
                </Routes>
              </Box>
            </div>
          </Box>
        </UserProvider>
      </ThemeProvider>
    </div>
  );
}

export default Nav;
