import { Typography } from "@mui/material";
import React from "react";

const ServiceListTitle = ({ children }: { children: string }) => {
  return (
    <Typography
      sx={{
        fontWeight: "700",
        paddingX: 10,
        fontSize: {
          xs: "27px",
          sm: "37px",
        },
        textAlign: "center",
        lineHeight: {
          xs: "37px",
          sm: "67px",
        },
      }}
      mb={4}
    >
      {children}
    </Typography>
  );
};

export default ServiceListTitle;
