import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import { Service } from "../../../models/Service";
import { useNavigate } from "react-router-dom";
import getLogoByService from "../../../utils/getLogoByService";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";

interface ServiceCardProps {
  service: Service;
  embedded?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ServiceCard = ({ service, embedded = false }: ServiceCardProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectService = () => {
    if (embedded) {
      window.open(
        `/embed/service/${service.serviceid}`,
        "popupWindow",
        "width=1200, height=1000"
      );
      return;
    }
    navigate(`/service/${service.serviceid}`);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mx: 2,
          marginBottom: 4,
        }}
        data-testid="service-card"
        onClick={onSelectService}
      >
        <Tooltip title={service?.title}>
          <Card
            key={service?.title}
            sx={{ height: 85, width: 240, backgroundColor: "#f7fafe" }}
            elevation={4}
          >
            <CardActionArea
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                px: 1,
                height: "100%",
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 62 }}
                image={`../assets/${getLogoByService(
                  service.serviceid as string
                )}`}
                alt={service.title}
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: 170,
                  px: 1,
                }}
              >
                <Typography
                  noWrap
                  variant={"body2"}
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bolder",
                    fontSize: 12,
                    width: "100%",
                  }}
                >
                  {service.title}
                </Typography>
                <Typography
                  noWrap
                  variant={"body2"}
                  sx={{ color: colors.grey[600], width: "100%" }}
                >
                  {service?.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Tooltip>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ServiceCard;
