import config from "../config";
import { PaymentMethod } from "../models/PaymentMethod";

export const paymentMethods: PaymentMethod[] = [
  {
    paymentProviderId: "MOMO",
    payItemId: config.REACT_APP_MOMO_PAYMENT_ID,
    name: [
      {
        lang: "en",
        text: "MTN Mobile Money",
      },
      {
        lang: "fr",
        text: "MTN Mobile Money FR",
      },
    ],
    instructions: [
      {
        lang: "en",
        text: "Dial *126# to validate your withdrawal in case you did not receive prompt.",
      },
      {
        lang: "fr",
        text: "Composez *126# pour valider votre retrait au cas où vous n'auriez pas reçu de demande de retrait.",
      },
    ],
    inputLabel: [
      {
        lang: "en",
        text: "Type your phone number",
      },
    ],
    validationRegex:
      "/^(237)?((650|651|652|653|654|680|681|682|683|684|686|687|688|689)[0-9]{6}$|(67[0-9]{7})$)/mi",
    accountNumberType: "STRING",
    min: 100,
    max: 1000000,
    currency: "XAF",
  },
  {
    paymentProviderId: "ORANGEWPAY",
    payItemId: config.REACT_APP_ORANGE_PAYMENT_ID,
    name: [
      {
        lang: "en",
        text: "Orange Payment",
      },
      {
        lang: "fr",
        text: "Orange Payment FR",
      },
    ],
    instructions: [
      {
        lang: "en",
        text: "Dial #150*50# to validate your withdrawal in case you did not receive prompt.",
      },
      {
        lang: "fr",
        text: "Composez #150*50# pour valider votre retrait au cas où vous n'auriez pas reçu de demande de retrait.",
      },
    ],
    inputLabel: [
      {
        lang: "en",
        text: "Type your phone number",
      },
    ],
    validationRegex:
      "/^(237)?((655|656|657|658|659|686|687|688|689)[0-9]{6}$|(69[0-9]{7})$)/mi",
    accountNumberType: "STRING",
    min: 100,
    max: 1000000,
    currency: "XAF",
  },
  {
    paymentProviderId: "CMEXPRESSUNIONCC",
    payItemId: config.REACT_APP_EU_PAYMENT_ID,
    name: [
      {
        lang: "en",
        text: "Express Union Payment",
      },
      {
        lang: "fr",
        text: "Express Union Payment FR",
      },
    ],
    instructions: [
      {
        lang: "en",
        text: "",
      },
      {
        lang: "fr",
        text: "",
      },
    ],
    inputLabel: [
      {
        lang: "en",
        text: "Type your phone number",
      },
    ],
    validationRegex: "/^(\\+{0,1})([a-z0-9]+)$/mi",
    accountNumberType: "MSISDN",
    min: 100,
    max: 1000000,
    currency: "XAF",
  },
  /*{
    paymentProviderId: "GIMACPAY",
    payItemId: "13246",
    name: [
      {
        lang: "en",
        text: "GIMAC PAY",
      },
      {
        lang: "fr",
        text: "GIMAC PAY",
      },
    ],
    instructions: [
      {
        lang: "en",
        text: "Dial *126# to validate your withdrawal in case you did not receive prompt.",
      },
      {
        lang: "fr",
        text: "Composez *126# pour valider votre retrait au cas où vous n'auriez pas reçu de demande de retrait.",
      },
    ],
    inputLabel: [
      {
        lang: "en",
        text: "Type your phone number",
      },
    ],
    validationRegex:
      "/^(237)?((650|651|652|653|654|680|681|682|683|684|686|687|688|689)[0-9]{6}$|(67[0-9]{7})$)/mi",
    accountNumberType: "STRING",
    min: 100,
    max: 1000000,
    currency: "XAF",
    customMethods: true,
  },*/
];
