import { Box } from "@mui/material";
import { ReactNode } from "react";

const CenterContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", overflowX: "hidden" }}
    >
      {children}
    </Box>
  );
};

export default CenterContainer;
