import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import fetchApi from "../../../api/fetchApi";
import useDimensions from "../../../hooks/useDimensions";
import useUser from "../../../hooks/useUser";
import { PaymentMethod } from "../../../models/PaymentMethod";
import BoxDetailsPayment from "../../../shared/boxDetailsPayment";
import Section from "../../layout/section";
import SelectPaymentMethod from "../SelectPaymentMethod";
import Button from "../button/customButton";
import CustomInput from "../inputs/cutsomInput";
import { StepperProps } from "./stepFormOne";
import { t } from "i18next";

export type QueryFieldId = "SVCNMB" | "CUSNMB" | "BILLNMB" | "QRCODE";

interface stepTwoProps {
  handleReset: () => void;
  handleGoBack: any;
  paymentMode: PaymentMethod | undefined;
  setisLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  setPaymentMode: React.Dispatch<
    React.SetStateAction<PaymentMethod | undefined>
  >;
}

const StepFormTwo = ({
  service,
  selectedPackage,
  paymentMode,
  setPaymentMode,
  handleNext,
  handleReset,
  handleGoBack,
  setisLoading,
  isLoading = false,
}: StepperProps & stepTwoProps) => {
  const { isSmall } = useDimensions();
  const { updateUser, user } = useUser();
  const { handleSubmit, control, setValue } = useForm();
  const [errorSelected, SetErrorSelected] = useState(false);

  const minWidth = isSmall ? "100%" : "150px";
  const marginBottom = isSmall ? 1 : 0;
  const position = isSmall ? "relative" : "relative";

  useEffect(() => {
    setPaymentMode(user?.paymentMode);
    if (user?.customerNumber?.length) {
      setValue("customerNumber", user?.customerNumber);
    }
  }, []);

  useEffect(() => {
    if (paymentMode) SetErrorSelected(false);
  }, [paymentMode]);

  const onSubmit = (data: any) => {
    if (!paymentMode) {
      SetErrorSelected(true);
      return;
    }

    updateUser({
      ...data,
      amount: selectedPackage?.amountLocalCur || 0,
      payItemId: selectedPackage?.payItemId,
      paymentProvider: paymentMode?.payItemId,
      paymentMode,
    });

    handleNext();
  };

  const createPattern = (regex: string) => {
    const regexMatch = String(regex).match(/\/(.*)\/(.*)/);
    // eslint-disable-next-line
    const pattern = new RegExp(
      regexMatch && regexMatch[1] ? regexMatch[1] : "",
      regexMatch && regexMatch[2] ? regexMatch[2] : ""
    );
    return pattern;
  };

  useEffect(() => {
    setisLoading(true);
    fetchApi("quotestd")
      .post({
        payItemId: user?.selectedPackage?.payItemId,
        amount: Number(user?.selectedPackage?.amountLocalCur),
      })
      .then((e) => {
        updateUser({
          serviceCharge: Number(e.amountLocalCur || 0),
        });
      })
      .finally(() => setisLoading(false));
  }, []);

  return (
    <>
      <Box>
        <Box
          display={isSmall ? "block" : "flex"}
          justifyContent="space-between"
          alignItems="flex-start"
          paddingX={isSmall ? 2 : 10}
        >
          <BoxDetailsPayment
            service={service}
            selectedPackage={selectedPackage}
            isLoading={isLoading}
          />

          <Box
            sx={{
              width: isSmall ? "unset" : "40%",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <SelectPaymentMethod
                paymentMode={paymentMode}
                setPaymentMode={setPaymentMode}
                errorMessage={errorSelected ? t("errors.field_required") : ""}
              />

              <Box>
                <CustomInput
                  id={"account_number-id"}
                  //source={"Decoder Number"}
                  control={control}
                  name="customerNumber"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  label={t("stepper_2.your_mobile_money_number")}
                  disabled={!paymentMode}
                  required
                  rules={{
                    required: t("errors.field_required"),
                    pattern: {
                      value: createPattern(paymentMode?.validationRegex || "/"),
                      message: t("errors.invalid_phone_number"),
                    },
                  }}
                  placeholder={t(
                    "stepper_2.your_mobile_money_number_placeholder"
                  )}
                  defaultValue={user?.customerNumber}
                />
              </Box>

              <Section title={t("stepper_2.additional detail")}>
                <Box>
                  <CustomInput
                    name={"reference"}
                    //source={"Decoder Number"}
                    control={control}
                    sx={{ width: "100%", paddingBottom: 4 }}
                    label={t("stepper_2.payment_reference")}
                    placeholder={t("stepper_2.payment_reference_placeholder")}
                    defaultValue={user?.customerEmailaddress}
                  />
                  <CustomInput
                    name={"customerPhoneNumber"}
                    control={control}
                    //source={"Decoder Number"}
                    sx={{ width: "100%", paddingBottom: 8 }}
                    variant="outlined"
                    label={t("stepper_2.notification_phone_number")}
                    placeholder={t(
                      "stepper_2.notification_phone_number_placeholder"
                    )}
                    rules={{
                      pattern: {
                        value: new RegExp(/^6(5|7|8|9)[0-9]{7}$/),
                        message: "invalid phone number",
                      },
                    }}
                  />
                </Box>
              </Section>
            </form>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position,
          paddingTop: 5,
          bottom: 50,
          width: "100%",
        }}
      >
        <Box paddingX={isSmall ? 2 : 10}>
          <Box
            sx={{
              display: isSmall ? "block" : "flex",
              justifyContent: "space-between",
            }}
          >
            {isSmall ? (
              <>
                <Button
                  data-testid="previous-button"
                  variant="contained"
                  size="large"
                  sx={{ minWidth, marginBottom }}
                  //disabled={disableActionButton}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("button.proceed")}
                </Button>

                <Button
                  data-testid="cancel-button"
                  variant="outlined"
                  size="large"
                  color="error"
                  onClick={handleReset}
                  sx={{ minWidth, marginBottom }}
                  //disabled={disableActionButton}
                >
                  {t("button.cancel")}
                </Button>

                <Button
                  data-testid="previous-button"
                  variant="outlined"
                  size="large"
                  sx={{
                    marginRight: isSmall ? 0 : 4,
                    minWidth,
                    marginBottom,
                  }}
                  //disabled={disableActionButton}
                  onClick={handleGoBack}
                >
                  {t("button.previous")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  data-testid="cancel-button"
                  variant="outlined"
                  size="large"
                  color="error"
                  onClick={handleReset}
                  sx={{ minWidth, marginBottom }}
                  //disabled={disableActionButton}
                >
                  {t("button.cancel")}
                </Button>
                <Box>
                  <Button
                    data-testid="previous-button"
                    variant="outlined"
                    size="large"
                    sx={{
                      marginRight: isSmall ? 0 : 4,
                      minWidth,
                      marginBottom,
                    }}
                    //disabled={disableActionButton}
                    onClick={handleGoBack}
                  >
                    {t("button.previous")}
                  </Button>
                  <Button
                    data-testid="previous-button"
                    variant="contained"
                    size="large"
                    sx={{ minWidth }}
                    //disabled={disableActionButton}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t("button.proceed")}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StepFormTwo;
