/*eslint-disable*/

declare global {
  var KYC: EnvVariablesProps;
}

type EnvVariablesProps = {
  REACT_APP_PROXY_BASE_URL: string;
  REACT_APP_CANAL_PLUS_SERVICE_ID: string;
  REACT_APP_ENEO_SERVICE_ID: string;
  REACT_APP_CAMWATER_SERVICE_ID: string;
  REACT_APP_MOMO_PAYMENT_ID: string;
  REACT_APP_EU_PAYMENT_ID: string;
  REACT_APP_ORANGE_PAYMENT_ID: string;
  REACT_APP_REPORT_LINK: string;
  REACT_APP_DOUANES_SERVICE_ID: string;
  REACT_APP_DGI_SERVICE_ID: string;
  REACT_APP_DSTV_SERVICE_ID: string;
  REACT_APP_STARTIMES_SERVICE_ID: string;
  REACT_APP_BILL_SEARCH_FEE: string;
  REACT_APP_CAMTEL_DATA_SERVICE_ID: string;
};

const getConfigValue = (key: keyof EnvVariablesProps, defaultValue: string) => {
  if (global.KYC && global.KYC[key] !== defaultValue) {
    return global.KYC[key];
  }

  if (process.env.NODE_ENV !== "production" && process.env[key]) {
    return String(process.env[key]);
  }

  console.log(`The key ${key} in index.html should be replaced!`);
  return global.KYC && global.KYC[key];
};

const config: EnvVariablesProps = {
  REACT_APP_PROXY_BASE_URL: getConfigValue(
    "REACT_APP_PROXY_BASE_URL",
    "VARPROXYBASEURL"
  ),
  REACT_APP_CANAL_PLUS_SERVICE_ID: getConfigValue(
    "REACT_APP_CANAL_PLUS_SERVICE_ID",
    "VARCANALPLUSSERVICEID"
  ),
  REACT_APP_DSTV_SERVICE_ID: getConfigValue(
    "REACT_APP_DSTV_SERVICE_ID",
    "VARDSTVSERVICEID"
  ),
  REACT_APP_CAMTEL_DATA_SERVICE_ID: getConfigValue(
    "REACT_APP_CAMTEL_DATA_SERVICE_ID",
    "VARCAMTELDATASERVICEID"
  ),
  REACT_APP_STARTIMES_SERVICE_ID: getConfigValue(
    "REACT_APP_STARTIMES_SERVICE_ID",
    "VARSTARTIMESSERVICEID"
  ),
  REACT_APP_ENEO_SERVICE_ID: getConfigValue(
    "REACT_APP_ENEO_SERVICE_ID",
    "VARENEOSERVICEID"
  ),
  REACT_APP_CAMWATER_SERVICE_ID: getConfigValue(
    "REACT_APP_CAMWATER_SERVICE_ID",
    "VARCAMWATERSERVICEID"
  ),
  REACT_APP_MOMO_PAYMENT_ID: getConfigValue(
    "REACT_APP_MOMO_PAYMENT_ID",
    "VARMOMOPAYMENTID"
  ),
  REACT_APP_EU_PAYMENT_ID: getConfigValue(
    "REACT_APP_EU_PAYMENT_ID",
    "VAREUPAYMENTID"
  ),
  REACT_APP_ORANGE_PAYMENT_ID: getConfigValue(
    "REACT_APP_ORANGE_PAYMENT_ID",
    "VARORANGEPAYMENTID"
  ),
  REACT_APP_REPORT_LINK: getConfigValue(
    "REACT_APP_REPORT_LINK",
    "VARREPORTLINK"
  ),
  REACT_APP_DOUANES_SERVICE_ID: getConfigValue(
    "REACT_APP_DOUANES_SERVICE_ID",
    "VARDOUANESSERVICEID"
  ),
  REACT_APP_DGI_SERVICE_ID: getConfigValue(
    "REACT_APP_DGI_SERVICE_ID",
    "VARDGISERVICEID"
  ),
  REACT_APP_BILL_SEARCH_FEE: getConfigValue(
    "REACT_APP_BILL_SEARCH_FEE",
    "VARBILLSEARCHFEE"
  ),
};

export default config;
