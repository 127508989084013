import { useParams } from "react-router-dom";
import {
  useQueryCashout,
  useQueryProduct,
  useQueryService,
} from "../../api/queries";
import Body from "../../components/layout/container/body";
import Loading from "../../components/ui/loading";
import useApp from "../../hooks/useApp";
import AboutPage from "../about";
import ServiceList from "../serviceList";

const Home = () => {
  const { categoryName = undefined } = useParams();
  const { isLoading: isLoadingCashout } = useQueryCashout();
  const { isLoading: isLoadingService } = useQueryService();
  const { isLoading: isLoadingProduct } = useQueryProduct();
  const { appStarted, isLoading } = useApp();

  const isDataLoading =
    isLoadingCashout || isLoadingService || isLoadingProduct || isLoading;

  if (isDataLoading) {
    return <Loading isLoading={isDataLoading} />;
  }

  return (
    <Body>
      {!isLoading &&
        (appStarted ? (
          <ServiceList categoryName={categoryName} />
        ) : (
          <AboutPage />
        ))}
    </Body>
  );
};

export default Home;
