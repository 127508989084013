import { useState, useEffect } from "react";
import { MAX_RECENT_CONTRACTNUMBER_USED } from "../constants/constant";

type ContractNumber = string;

const getContractNumbers = (serviceId: string): ContractNumber[] => {
  const contracts = JSON.parse(
    localStorage.getItem(`contracts_${serviceId}`) || "[]"
  ) as ContractNumber[];

  return contracts;
};

const saveContractNumber = (
  serviceId: string,
  contractNumber: ContractNumber
): void => {
  const existingContracts = getContractNumbers(serviceId);

  if (existingContracts.includes(contractNumber)) {
    return;
  }
  const updatedContracts = [...existingContracts, contractNumber];

  if (updatedContracts.length > MAX_RECENT_CONTRACTNUMBER_USED) {
    updatedContracts.shift();
  }

  localStorage.setItem(
    `contracts_${serviceId}`,
    JSON.stringify(updatedContracts)
  );
};

const useRecentContractNumbers = (serviceId: string) => {
  const [recentContractNumbers, setRecentContractNumbers] = useState<
    ContractNumber[]
  >([]);

  useEffect(() => {
    const contracts = getContractNumbers(serviceId);

    setRecentContractNumbers(contracts);
  }, [serviceId]);

  const addContractNumber = (
    contractNumber: ContractNumber | undefined
  ): void => {
    if (!contractNumber) {
      return;
    }

    saveContractNumber(serviceId, contractNumber);
    setRecentContractNumbers([...recentContractNumbers, contractNumber]);
  };

  return { recentContractNumbers, addContractNumber };
};

export default useRecentContractNumbers;
