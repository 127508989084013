import { Box, CircularProgress, Typography } from "@mui/material";
import Card from "../../ui/card";
import useDimensions from "../../../hooks/useDimensions";
import { t } from "i18next";

const ProcessingCard = () => {
  const { isSmall } = useDimensions();
  const maxWidth = isSmall ? "unset" : "38%";

  return (
    <Box sx={{ maxWidth }}>
      <Card
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          padding: isSmall ? 5 : 10,
        }}
      >
        <Box>
          <Box display={"flex"} justifyContent={"center"}>
            <CircularProgress color="primary" title="loading" />
          </Box>
          <Typography
            fontSize={16}
            fontWeight={600}
            textAlign={"center"}
            color={"#0D109F"}
            paddingBottom={3}
          >
            {t("stepper_3.please_wait")}
          </Typography>

          <Typography fontSize={32} fontWeight={600} textAlign={"center"}> 
            {t("stepper_3.processing")}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default ProcessingCard;
