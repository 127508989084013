import { useState, useEffect } from "react";

const GET_STARTED_LOCAL_STORAGE_NAME = "GET_STARTED";

const useApp = () => {
  const [appStarted, setAppStarted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const saveUserGetStarted = (): void => {
    setIsLoading(true);
    setTimeout(() => {}, 1200);
    localStorage.setItem(
      GET_STARTED_LOCAL_STORAGE_NAME,
      JSON.stringify(new Date())
    );
  };

  const isAppStarted = (): boolean => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
    const data = localStorage.getItem(GET_STARTED_LOCAL_STORAGE_NAME) || "";
    return Boolean(data);
  };

  useEffect(() => {
    const isUserAppStarted = isAppStarted();
    setAppStarted(isUserAppStarted);
  }, [appStarted]);

  const addUserGetStarted = (): void => {
    if (appStarted) {
      return;
    }
    saveUserGetStarted();
    window.location.reload();
  };

  return { appStarted, isLoading, addUserGetStarted };
};

export default useApp;
