import { useQuery } from "@tanstack/react-query";
import fetchApi from "../fetchApi";
import { ProductPackage } from "../../models/Product";

const useQueryProduct = (options?: any) => {
  return useQuery(
    ["product"],
    () => fetchApi("product").get<ProductPackage[]>(),
    {
      staleTime: 1000 * 60 * 10,
      refetchOnWindowFocus: false,
    }
  );
};

export default useQueryProduct;
