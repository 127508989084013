import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useApp from "../hooks/useApp";
import useDimensions from "../hooks/useDimensions";

const GoToCanalBtn = ({ size = "normal" }: { size?: "normal" | "large" }) => {
  const {t} = useTranslation()
  const { isSmall } = useDimensions();
  const { addUserGetStarted } = useApp();

  const sx = isSmall
    ? size === "large"
      ? {
          backgroundColor: " #FDBF50",
          color: "#0D109F",
          height: "46px",
          minWidth: "328px",
          padding: "9px, 18px, 9px, 18px",
          borderRadius: "2.27px",
        }
      : {
          backgroundColor: " #FDBF50",
          color: "#0D109F",
          height: "30px",
          minWidth: "100px",
          padding: "14px",
          borderRadius: "2.27px",
        }
    : size === "large"
    ? {
        backgroundColor: " #FDBF50",
        color: "#0D109F",
        height: "60px",
        minWidth: "233px",
        padding: "19px, 33px, 19px, 33px",
        borderRadius: "8px",
      }
    : {
        backgroundColor: " #FDBF50",
        color: "#0D109F",
        height: "55px",
        minWidth: "153px",
        padding: "19px, 33px, 19px, 33px",
        borderRadius: "10px",
      };

  return (
    <Button
      variant="contained"
      size="large"
      sx={{
        "&:hover": {
          color: "#fff",
        },
        ...sx,
      }}
      onClick={addUserGetStarted}
    >
      <Typography fontSize={12} lineHeight={15.2} fontWeight={600}>
        {t("get_started")}
      </Typography>
    </Button>
  );
};

export default GoToCanalBtn;
