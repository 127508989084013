import { Alert } from "@mui/material";
import React from "react";
import useDimensions from "../../../hooks/useDimensions";
import { t } from "i18next";

const ReviewAlert = () => {
  const { isSmall } = useDimensions();
  return (
    <Alert
      sx={{ mx: isSmall ? 10 : 15, mt: isSmall ? 2 : 5, mb: isSmall ? 4 : 0 }}
      severity="warning"
    >
      {t("please_review")}
    </Alert>
  );
};

export default ReviewAlert;
