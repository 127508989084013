import { Box, CircularProgress, Typography, colors } from "@mui/material";
import Card from "../../ui/card";
import formatCurrency from "../../../utils/formatCurrency";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useDimensions from "../../../hooks/useDimensions";
import useUser from "../../../hooks/useUser";
import { TRANSACTION_STATUS } from "../../../constants/constant";
import { t } from "i18next";

const SuccessCard = ({
  accountNumber,
  amount,
  text,
}: {
  accountNumber: number;
  amount: number;
  text?: string;
}) => {
  const { isSmall } = useDimensions();
  const { user, billSearch } = useUser();
  const { success, pending } = TRANSACTION_STATUS;
  const maxWidth = isSmall ? "unset" : "38%";

  const totalAmount =
    Number(amount) +
    Number(user?.serviceCharge || 0) +
    Number(billSearch?.processsingServiceCharge || 0) -
    Number(amount);

  return (
    <Box sx={{ maxWidth }}>
      <Card
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          padding: isSmall ? 3 : 10,
          paddingY: 2,
        }}
      >
        <Box>
          <Box display={"flex"} justifyContent={"center"}>
            <CheckCircleIcon color="success" sx={{ fontSize: 70 }} />
          </Box>

          <Typography
            fontSize={32}
            fontWeight={600}
            textAlign={"center"}
            color={"#057A18"}
            paddingBottom={5}
          > 
            {t("stepper_3.successful_payment")}{" "}
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{ fontWeight: "400", fontSize: 19, color: "#707070" }} 
            paddingBottom={5} 
          >
            {t("stepper_3.payment_id")}{accountNumber} {t("stepper_3.payment_message")}
          </Typography>
          <Typography
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
            paddingBottom={5}
          >
            {formatCurrency(totalAmount)}
          </Typography>
          {text === pending && (
            <>
              <Box
                height={5}
                width={"100%"}
                sx={{ backgroundColor: "gray", marginBottom: 5 }}
              >
                <Box
                  height={5}
                  width={"50%"}
                  sx={{ backgroundColor: "green", marginBottom: 5 }}
                />
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <CircularProgress color="primary" title="loading" />
              </Box>
              <Typography
                style={{ fontSize: 24, fontWeight: "500", textAlign: "center" }}
              >
                {user?.billService?.merchant
                  ? t("stepper_3.processing_bill")
                  : t("stepper_3.processing_subscription")}
              </Typography>
            </>
          )}

          {text === success && (
            <>
              <Box
                height={5}
                width={"100%"}
                sx={{ backgroundColor: "gray", marginBottom: 5 }}
              >
                <Box
                  height={5}
                  width={"100%"}
                  sx={{ backgroundColor: "green", marginBottom: 5 }}
                />
              </Box>
              <Typography
                style={{ fontSize: 24, fontWeight: "500", textAlign: "center" }} 
              >
                {user?.billService?.merchant
                  ? t("stepper_3.bill_paid")
                  : t("stepper_3.subscription_renewed")}
              </Typography>
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default SuccessCard;
