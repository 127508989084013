import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  colors,
  Tooltip,
  Typography,
} from "@mui/material";
import { ProductPackage } from "../../../models/Product";
import { Service } from "../../../models/Service";
import formatCurrency from "../../../utils/formatCurrency";
import getLogoByService from "../../../utils/getLogoByService";
import useUser from "../../../hooks/useUser";

interface ProductPackageCardProps {
  service: Service;
  productPackage: ProductPackage;
  setSelectedPackage: React.Dispatch<
    React.SetStateAction<ProductPackage | null>
  >;
}

const ProductPackageCard = ({
  productPackage,
  service,
  setSelectedPackage,
}: ProductPackageCardProps) => {
  const { updateUser } = useUser();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mx: 2,
        marginBottom: 4,
      }}
      data-testid="service-card"
    >
      <Tooltip title={productPackage?.name}>
        <Card
          onClick={() => {
            setSelectedPackage(productPackage);
            updateUser({ selectedPackage: productPackage });
          }}
          sx={{ height: 85, width: 240, backgroundColor: "#f7fafe" }}
          elevation={4}
        >
          <CardActionArea
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              px: 1,
              height: "100%",
            }}
          >
            <CardMedia
              component="img"
              sx={{ width: 62 }}
              image={`/assets/${getLogoByService(service.serviceid as string)}`}
              alt={service.title}
            />
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                width: 170,
                px: 1,
              }}
            >
              <Typography
                noWrap
                variant={"body2"}
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bolder",
                  fontSize: 12,
                  width: "100%",
                }}
              >
                {productPackage?.name}
              </Typography>
              <Typography
                noWrap
                variant={"body2"}
                sx={{ color: colors.grey[600], width: "100%" }}
              >
                {formatCurrency(productPackage.amountLocalCur)}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Tooltip>
    </Box>
  );
};

export default ProductPackageCard;
