import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import HeaderCardService from "../components/layout/header/headerCardService";
import Card from "../components/ui/card";
import Chip from "../components/ui/chip";
import StackList from "../components/ui/stackList";
import { TOPUP } from "../constants/constant";
import useDimensions from "../hooks/useDimensions";
import useUser from "../hooks/useUser";
import { ProductPackage } from "../models/Product";
import { Service } from "../models/Service";
import formatCurrency from "../utils/formatCurrency";

interface BoxDetailsPaymentProps {
  service: Service;
  selectedPackage: ProductPackage | null;
  isLoading?: boolean;
}

const BoxDetailsPayment = ({
  service,
  selectedPackage,
  isLoading = false,
}: BoxDetailsPaymentProps) => {
  const { user } = useUser();
  const { isSmall } = useDimensions();
  const listData = [
    {
      title: t("product_name"),
      value: selectedPackage?.name,
    },
    {
      title: service?.type === TOPUP ? t("phone_number") : t("decoder_number"),
      value: user?.cardNumber,
    },
  ];

  const subDetails = [
    {
      title: t("amount"),
      value: isLoading
        ? "..."
        : formatCurrency(selectedPackage?.amountLocalCur),
    },
    {
      title: t("service_charge"),
      value: formatCurrency(0),
    },
    {
      title: t("total_amount"),
      value: isLoading
        ? "..."
        : formatCurrency(Number(selectedPackage?.amountLocalCur) + 0),
      valueStyles: { fontSize: 24, color: " #D92D20", fontWeight: "700" },
    },
  ];

  return (
    <Box
      sx={{
        width: isSmall ? "unset" : "40%",
        justifyContent: "center",
        mt: isSmall ? 1 : 2,
        marginBottom: isSmall ? 1 : 5,
      }}
    >
      <HeaderCardService service={service} />
      <Card>
        <Box
          sx={{ padding: isSmall ? "8px 16px" : "10px 24px" }}
          display={"flex"}
          marginBottom={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            {t("amount").toUpperCase()}
            <Typography variant="body1" fontSize={24} fontWeight={"700"}>
              {!isLoading &&
                formatCurrency(selectedPackage?.amountLocalCur, "fr-CM", "XAF")}
            </Typography>
          </Box>
          <Chip label={t("not_paid")} variant="filled" status={false} />
        </Box>
        <Box paddingBottom={2}>
          <StackList spacing={2} list={listData} margin={2} />
        </Box>
      </Card>

      <StackList spacing={2} list={subDetails} marginBottom={1} />
    </Box>
  );
};

export default BoxDetailsPayment;
