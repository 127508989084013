const createPattern = (regex: string) => {
  const regexMatch = String(regex).match(/\/(.*)\/(.*)/);
  // eslint-disable-next-line
  const pattern = new RegExp(
    regexMatch && regexMatch[1] ? regexMatch[1] : "",
    regexMatch && regexMatch[2] ? regexMatch[2] : ""
  );
  return pattern;
};

export default createPattern;
