import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button, IconButton, Theme, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import GoToCanalBtn from "../../../shared/goToCanalBtn";
import Logo from "../../../shared/logo";
import useApp from "../../../hooks/useApp";
import useDimensions from "../../../hooks/useDimensions";
import getSystemLanguage from "../../../utils/getSystemLanguage";

interface HeaderProps {
  handleDrawerOpenClose: () => void;
  isAboutPage?: boolean;
}

const Header = ({ handleDrawerOpenClose }: HeaderProps) => {
  const { appStarted } = useApp();
  const { isSmall } = useDimensions();

  return (
    <AppBar position="static">
      {!appStarted ? (
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingY: 1,
          }}
        >
          <Logo position="left" />
          <Box sx={{ paddingRight: { sm: 0, md: 5, lg: 10 }, display: "flex" }}>
            {!isSmall && (
              <Button
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: " white",
                  color: "black",
                  marginRight: 2,

                  height: "55px",
                  minWidth: "153px",
                  padding: "19px, 33px, 19px, 33px",
                  borderRadius: "10px",
                  "&:hover": {
                    color: "#fff",
                  },
                }}
                startIcon={<LanguageOutlinedIcon />}
                endIcon={<KeyboardArrowDownOutlinedIcon />}
              >
                {getSystemLanguage()}
              </Button>
            )}

            <GoToCanalBtn />
          </Box>
        </Toolbar>
      ) : (
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleDrawerOpenClose}
          >
            <MenuIcon />
          </IconButton>
          <Logo />
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Header;
