const getColorByStatus = (
  val: string | boolean
): "success" | "error" | "warning" | "info" => {
  switch (val) {
    case "SUCCESS":
    case "COMPLETED":
    case "PAID":
    case "APPROVED":
    case true:
      return "success";
    case "FAILURE":
    case "FAILED":
    case "ERRORED":
    case "ERROR":
    case false:
    case "REJECTED":
      return "error";
    case "PENDING":
    case "NOTPAID":
    case "DEBITED":
    case "UNDERINVESTIGATION":
    case "ERROREDREFUNDED":
    case "WARNING":
      return "warning";
    case "CREATED":
    case "NEW":
    case "INPROCESS":
      return "info";
    default:
      return "info";
  }
};

export default getColorByStatus;
