import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import HeaderCardService from "../components/layout/header/headerCardService";
import Card from "../components/ui/card";
import Chip from "../components/ui/chip";
import StackList from "../components/ui/stackList";
import useDimensions from "../hooks/useDimensions";
import useUser from "../hooks/useUser";
import { Service } from "../models/Service";
import formatCurrency from "../utils/formatCurrency";

interface BoxBillPaymentProps {
  service: Service;
  isLoading?: boolean;
}

const BoxBillPayment = ({
  service,
  isLoading = false,
}: BoxBillPaymentProps) => {
  const { user, billSearch } = useUser();
  const { totalAmount, currentServiceCharge, processsingServiceCharge } =
    billSearch;

  const { isSmall } = useDimensions();
  const listData = [
    {
      title: t("customer_name"),
      value: user?.billService?.customerName,
    },
    {
      title: t("bill_number"),
      value: user?.billService?.billNumber,
    },
    {
      title: t("customer_number"),
      value: user?.billService?.customerNumber,
    },
    {
      title: t("contract_number"),
      value: user?.billService?.serviceNumber,
    },
  ];

  const subDetails = [
    {
      title: t("amount"),
      value: isLoading
        ? "..."
        : formatCurrency(user?.billService?.amountLocalCur || 0),
    },

    {
      title: t("service_charge"),
      value: Boolean(totalAmount)
        ? formatCurrency(currentServiceCharge)
        : "...",
    },
    {
      title: t("processing_charge"),
      value: Boolean(totalAmount)
        ? formatCurrency(processsingServiceCharge)
        : "...",
    },
    {
      title: t("total_amount"),
      value: totalAmount
        ? formatCurrency(totalAmount + processsingServiceCharge)
        : "...",
      valueStyles: { fontSize: 24, color: " #D92D20", fontWeight: "700" },
    },
  ];

  return (
    <Box
      sx={{
        width: isSmall ? "unset" : "40%",
        justifyContent: "center",
        mt: isSmall ? 1 : 2,
        marginBottom: isSmall ? 1 : 5,
      }}
    >
      <HeaderCardService service={service} />
      <Card>
        <Box
          sx={{ padding: isSmall ? "8px 16px" : "10px 24px" }}
          display={"flex"}
          marginBottom={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            {t("amount").toUpperCase()}
            <Typography variant="body1" fontSize={24} fontWeight={"700"}>
              {Boolean(totalAmount) &&
                formatCurrency(
                  totalAmount + processsingServiceCharge,
                  "fr-CM",
                  "XAF"
                )}
            </Typography>
          </Box>
          <Chip label={t("not_paid")} variant="filled" status={false} />
        </Box>
        <Box paddingBottom={2}>
          <StackList spacing={2} list={listData} margin={2} />
        </Box>
      </Card>

      <StackList spacing={2} list={subDetails} marginBottom={1} />
    </Box>
  );
};

export default BoxBillPayment;
