import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactNode } from "react";
import Footer from "../footer";

const useStyles = makeStyles(() => ({
  fullScreen: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  backgroundImage: {
    backgroundImage: "url(./assets/background_large.png)",
  },
}));

const Body = ({
  children,
  isHomePage = true,
}: {
  children: ReactNode;
  isHomePage?: boolean;
}) => {
  const classes = useStyles();

  // Conditionnez la classe en fonction de isHomePage
  const containerClassName = !isHomePage
    ? `${classes.fullScreen} ${classes.backgroundImage}`
    : classes.fullScreen;

  return (
    <div className={containerClassName}>
      {children}
      <Footer />
    </div>
  );
};

export default Body;
