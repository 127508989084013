import { Alert, AlertTitle, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import fetchApi from "../../api/fetchApi";
import Container from "../../components/layout/container";
import ErrorCard from "../../components/layout/errorCard";
import LoadingCard from "../../components/layout/loadingCard";
import ProcessingCard from "../../components/layout/processingCard";
import SuccessCard from "../../components/layout/successCard";
import useDimensions from "../../hooks/useDimensions";
import useUser from "../../hooks/useUser";
import getErrorMessage from "../../utils/getErrorMessage";
import { TRANSACTION_STATUS } from "../../constants/constant";

const VerifyPage = () => {
  const { isSmall } = useDimensions();
  const navigate = useNavigate();
  const { success, pending, errored } = TRANSACTION_STATUS;

  const { user, updateUser, billSearch } = useUser();
  const { processsingServiceCharge } = billSearch;
  const [error, setError] = useState<string>("");
  const [data, setData] = useState<any>("");
  const [isFetching, setIsFetching] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [count, setCount] = useState<number>(0);
  const { id = "123" } = useParams();
  const [isApiRunning, setApiRunning] = useState(true);

  const isFinalResut = data?.status === errored || data?.status === success;

  const verifyPtn = () => {
    setIsFetching(true);
    fetchApi(`verifytx?trid=${String(id)}`)
      .get<any>()
      .then((e) => {
        if (e?.usrMsg) {
          setError(e?.usrMsg);
        } else {
          setData(e.at(0));
          updateUser({ paid: e.at(0)?.status === success });
          setApiRunning(!(e.at(0)?.status === success));
        }
      })
      .catch((err) => setError(err?.usrMsg))
      .finally(() => {
        setIsFetching(false);
        setIsDisplay(true);
      });
  };

  const activateService = () => {
    setCount(count + 1);
    fetchApi("quotestd")
      .post({
        payItemId: user?.billService?.payItemId || user?.payItemId,
        amount: Number(user?.billService?.amountLocalCur || user?.amount),
      })
      .then((e) => {
        fetchApi("collectstd")
          .post({
            quoteId: e.quoteId,
            customerPhonenumber: user?.customerNumber,
            customerEmailaddress: "cedricngoumti@gmail.com",
            customerName: "WebPAY",
            customerAddress: user?.reference || "WebPay",
            serviceNumber: user?.billService?.serviceNumber || user?.cardNumber,
            tag: user?.tag,
            trid: uuidv4(),
          })
          .then((data) => {
            updateUser({ activated: true });

            navigate(`${data.trid}`);
          });
      });
  };

  // Créez une fonction pour effectuer la vérification du statut.

  useEffect(() => {
    // Appelez fetchApiData une fois au chargement de la page
    verifyPtn();

    // Démarrez l'intervalle pour les appels API réguliers
    const apiInterval = setInterval(() => {
      if (isApiRunning) {
        verifyPtn();
      } else {
        clearInterval(apiInterval); // Arrêtez la minuterie lorsque isApiRunning est false
      }
    }, 20000);

    return () => clearInterval(apiInterval);
  }, [isApiRunning]);

  useEffect(() => {
    if (isDisplay && isFinalResut) {
      if (count === 0 && user?.paid && !user?.activated) activateService();
    }
  }, [data]);

  return (
    <Container bordered={!isSmall} sx={{ position: "relative" }}>
      {error && (
        <Box ml={1} mb={3}>
          <Alert
            severity={"error"}
            variant={"standard"}
            onClose={() => setError("")}
          >
            <AlertTitle sx={{ fontWeight: "bold" }}>{"Error"}</AlertTitle>
            {error}
          </Alert>
        </Box>
      )}
      <Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isFetching && !isDisplay && <ProcessingCard />}
          {data?.status === pending && (
            <LoadingCard amount={Number(data?.priceLocalCur)} />
          )}
          {data?.status === success && (
            <SuccessCard
              amount={Number(data?.priceLocalCur)}
              accountNumber={data?.receiptNumber}
              text={pending}
            />
          )}
          {data?.status === errored && (
            <ErrorCard
              amount={
                Number(data?.priceLocalCur) + Number(processsingServiceCharge)
              }
              accountNumber={data?.ptn}
              errorMessage={getErrorMessage(data?.errorCode)}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default VerifyPage;
