/**
 * Currency formatter function
 *
 * @param {Number} isoString - Value to be formatted
 */

const formatDate = (isoString: string) => {
  return new Date(isoString).toLocaleString();
};

export default formatDate;
