import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import TvIcon from "@mui/icons-material/Tv";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import { MenuItemProps } from "../../../navigation";
import MenuItem from "../../ui/menuItem";
import { useTranslation } from "react-i18next";
import { REPORT_LINK } from "../../../constants/constant";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface SideNavProps {
  open: boolean;
  handleDrawerOpenClose: () => void;
}

export default function SideNav({ open, handleDrawerOpenClose }: SideNavProps) {
  const { t } = useTranslation();
  const navigation: MenuItemProps[] = [
    {
      name: t("menu.home"),
      href: "/",
      icon: AppsOutlinedIcon,
    },
    {
      name: t("menu.tv_cable"),
      href: "/category/Cable TV",
      icon: TvIcon,
    },
    {
      name: t("menu.electricity_water"),
      href: "/category/Electricity & Water Bills",
      icon: BoltOutlinedIcon,
    },
    {
      name: t("menu.internet"),
      href: "/category/Data & Internet",
      icon: SwapVertOutlinedIcon,
    },
    {
      name: t("menu.taxes"),
      href: "/category/Tax & Government",
      icon: CancelPresentationOutlinedIcon,
    },
    {
      name: t("menu.report"),
      href: REPORT_LINK,
      icon: ReportGmailerrorredOutlinedIcon,
    },
  ];
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {" "}
        <DrawerHeader>
          <IconButton onClick={handleDrawerOpenClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        {/*<img src="/assets/logo_sidebar.png" alt="Logo" />*/}
        <Divider />
        <List>
          {navigation.map((menuItem, key) => (
            <span onClick={handleDrawerOpenClose} key={key}>
              <MenuItem menu={menuItem} />
            </span>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
