import { Typography, Box, colors, BoxProps, Tooltip } from "@mui/material";
import { isEmpty, isString } from "lodash";

export interface IKeyValueText extends BoxProps {
  title: string;
  value: string | React.ReactNode;
  isVertical?: boolean;
  titleStyles?: React.CSSProperties;
  valueStyles?: React.CSSProperties;
  show?: boolean;
}

const KeyValueText = ({
  title,
  value,
  isVertical = false,
  titleStyles,
  valueStyles,
  show = true,
  ...rest
}: IKeyValueText) => {
  const renderItems = (value: string | React.ReactNode) => {
    if (value) {
      if (isString(value)) {
        return (
          <Tooltip title={value} placement="left-start">
            <Typography
              noWrap
              data-testid="value-text-testid"
              sx={{
                width: "60%",
                textAlign: isVertical ? "left" : "right",
                ...valueStyles,
              }}
              color={"#000"}
            >
              {value}
            </Typography>
          </Tooltip>
        );
      }

      return value;
    }
  };

  return (
    <Box
      display={isVertical ? "block" : "flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      data-testid="box-test-id"
      style={{
        display: isEmpty(value) ? "none" : "",
      }}
      {...rest}
    >
      <Typography
        data-testid="key-text-testid"
        sx={{ fontSize: 16, fontWeight: "bold", ...titleStyles }}
        color={colors.grey[600]}
      >
        {title}:
      </Typography>
      {renderItems(value)}
    </Box>
  );
};

export default KeyValueText;
