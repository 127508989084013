import { Service } from "../models/Service";

const GIMAC_MERCHANT_NAME = "GIMAC_CASHOUT";

const groupServicesByCountry = (
  services: Service[]
): { [country: string]: Service[] } => {
  return services.reduce((acc: { [country: string]: Service[] }, service) => {
    const title = service.title;
    const countryIndex = title.lastIndexOf("-") + 1;
    const titleCountry = title.slice(countryIndex).trim().toLocaleLowerCase();
    const country =
      titleCountry.charAt(0).toUpperCase() +
      titleCountry.toLocaleLowerCase().slice(1);
    if (!acc[country]) {
      acc[country] = [];
    }
    acc[country].push(service);
    return acc;
  }, {});
};

const getGIMACPaymentMethod = (services: Service[]) => {
  const gimacServices = services.filter(
    (service) =>
      service.merchant === GIMAC_MERCHANT_NAME && service.type === "CASHOUT"
  );

  return groupServicesByCountry(gimacServices);
};

export default getGIMACPaymentMethod;
