const getButtonWidth = (
  customWidth: "large" | "normal" | "fullWidth",
  minWidth = "150px"
): string => {
  switch (customWidth) {
    case "large":
      return "350px";
    case "fullWidth":
      return "100%";
    case "normal":
    default:
      return minWidth;
  }
};

export default getButtonWidth;
