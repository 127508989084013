import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import config from "../config";

function useUser() {
  const { user, clearData, updateUser, saveUserData } = useContext(UserContext);

  const totalAmountBillSearch =
    Number(user?.billService?.amountLocalCur) +
    Number(user?.serviceCharge) -
    Number(user?.billService?.amountLocalCur);

  const billSearch = {
    totalAmount: totalAmountBillSearch,
    currentServiceCharge:
      Number(user?.serviceCharge) - Number(user?.billService?.amountLocalCur),
    processsingServiceCharge:
      totalAmountBillSearch /
        (1 - Number(config.REACT_APP_BILL_SEARCH_FEE) / 100) -
      totalAmountBillSearch,
  };

  return {
    user,
    clearData,
    updateUser,
    saveUserData,
    billSearch,
  };
}

export default useUser;
