import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Image404 } from "../../assets";
import Button from "../../components/ui/button/customButton";
import openReportLink from "../../utils/openReportLink";

const NotFound = () => {
  return (
    <Box
      sx={{
        // paddingBottom: 10,
        height: "100%",
        padding: {
          xs: 1,
          md: 5,
        },
      }}
    >
      <Paper
        sx={{
          boxShadow: {
            xs: "unset",
            // this is the default style of the paper component
            md: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
          },
          paddingX: {
            xs: 1,
            md: 5,
          },
          paddingY: {
            xs: 1,
            md: 15,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingX: {
              xs: 1,
              md: 12,
            },
            flexDirection: {
              xs: "column-reverse",
              md: "row",
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: "80%",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: {
                    xs: "24px",
                    md: "32px",
                  },
                  lineHeight: "44px",
                  fontFamily: "Plus Jakarta Sans",
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                }}
              >
                {t("notFoundPage.oops_the_page_you_are_looking")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "flex-start",
                  },
                }}
              >
                <Divider
                  sx={{
                    width: {
                      xs: "70%",
                      md: "90%",
                    },
                    marginY: 3,
                  }}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: {
                    xs: "12px",
                    md: "16px",
                  },
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                  lineHeight: {
                    xs: "24px",
                    md: "32px",
                  },
                  marginBottom: 3,
                }}
              >
                <Trans>
                  {t("notFoundPage.You_may_want_to_head_back")}
                  <br />
                </Trans>
                {t("notFoundPage.if_you_think_something_is_broken")}
              </Typography>
              <Link to="/">
                <Button
                  sx={{ marginBottom: 2 }}
                  size="large"
                  fullWidth
                  startIcon={<ArrowBackIcon />}
                >
                  {t("go_to_homepage")}
                </Button>
              </Link>
              <Button
                variant="outlined"
                type="submit"
                fullWidth
                size="large"
                startIcon={<AnnouncementOutlinedIcon />}
                sx={{
                  color: "#707070",
                  borderColor: "#C4C9D4",
                  "&:hover": {
                    borderColor: "#C4C9D4",
                  },
                }}
                onClick={openReportLink}
              >
                {t("button.report")}
              </Button>
            </Box>
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Box sx={{ width: "80%" }}>
              <img src={Image404} width="100%" alt="404" />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default NotFound;
