/**
 * Defines available payment providers - \"CMYUPCC\" - \"MOMO\" - \"ORANGEWPAY\" - \"CMYOOMEEMONEYCC\" - \"CMEXPRESSUNIONCC\"
 * @export
 * @enum {string}
 */
export enum EnumPaymentProvider {
  CMYUPCC = <any>"CMYUPCC",
  MOMO = <any>"MOMO",
  ORANGEWPAY = <any>"ORANGEWPAY",
  CMYOOMEEMONEYCC = <any>"CMYOOMEEMONEYCC",
  CMEXPRESSUNIONCC = <any>"CMEXPRESSUNIONCC",
}

export interface LocalizedFieldObject {
  /**
   *
   * @type {string}
   * @memberof LocalizedFieldObject
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof LocalizedFieldObject
   */
  lang?: string;
}

export interface PaymentMethodObject {
  /**
   *
   * @type {EnumPaymentProvider}
   * @memberof PaymentMethodObject
   */
  paymentProviderId?: EnumPaymentProvider;
  /**
   * Payment method display name
   * @type {Array<LocalizedFieldObject>}
   * @memberof PaymentMethodObject
   */
  name?: Array<LocalizedFieldObject>;
  /**
   * JSON object of i18n payment instructions
   * @type {Array<LocalizedFieldObject>}
   * @memberof PaymentMethodObject
   */
  instructions?: Array<LocalizedFieldObject>;
  /**
   * JSON object of i18n input labels
   * @type {Array<LocalizedFieldObject>}
   * @memberof PaymentMethodObject
   */
  inputLabel?: Array<LocalizedFieldObject>;
  /**
   * Validation - provided as a regular expression
   * @type {string}
   * @memberof PaymentMethodObject
   */
  validationRegex?: string;
  /**
   *
   * @type {EnumPaymentMethodAccountType}
   * @memberof PaymentMethodObject
   */
  accountNumberType?: EnumPaymentMethodAccountType;
  /**
   * Minimum amount accepted by the provider
   * @type {number}
   * @memberof PaymentMethodObject
   */
  min?: number;
  /**
   * Maximum amount accepted by the provider
   * @type {number}
   * @memberof PaymentMethodObject
   */
  max?: number;
  /**
   *
   * @type {EnumCurrency}
   * @memberof PaymentMethodObject
   */
  currency?: EnumCurrency;
}

/**
 * Defines payment method account types - \"MSISDN\" - \"NUMBER\" - \"STRING\"
 * @export
 * @enum {string}
 */
export enum EnumPaymentMethodAccountType {
  MSISDN = <any>"MSISDN",
  NUMBER = <any>"NUMBER",
  STRING = <any>"STRING",
}

export enum EnumCurrency {
  XAF = <any>"XAF",
  EUR = <any>"EUR",
  USD = <any>"USD",
  CAD = <any>"CAD",
}

export type LANGUAGE = "en" | "fr";
