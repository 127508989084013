import config from "../config";

const serviceLogos = {
  90006: "CanalPlus.png",
  10039: "eneo.png",
  [config.REACT_APP_CAMWATER_SERVICE_ID]: "camwater.png",
  202303: "douanes.jpeg",
  20021: "startimes.png",
  202301: "dstv.png",
  202302: "dgi.png",
  20103: "blue.png",
  20104: "blue.png",
  202404: "cnps.png",
  1500052: "cnps.png",
  12351: "cnps.png",
  1004: "cnps.png",
  10041: "cnps.png",
};

const getLogoByService = (val: number | string): string => {
  return serviceLogos[`${val}`] || "CanalPlus.png";
};

export default getLogoByService;
