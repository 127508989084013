// Définir un type pour les messages d'erreur
type ErrorMessage = {
  code: number;
  description: string;
};

const errorMessages: ErrorMessage[] = [
  {
    code: 0,
    description:
      "Transaction processing did not trigger an error - it’s either still being processed or was already successfully processed. Check the payment status.",
  },
  {
    code: 2,
    description: "Transaction is under investigation. Contact support.",
  },
  {
    code: 3,
    description: "Transaction has been reversed. Contact support.",
  },
  {
    code: 702000,
    description:
      "Transaction failed due to a general payment error. Contact support.",
  },
  {
    code: 702100,
    description:
      "Transaction failed when initializing communications with the service provider. Contact support.",
  },
  {
    code: 702101,
    description:
      "Destination does not match expected value range. For example: The MSISDN may not be within the number range accepted by the Service Provider.",
  },
  {
    code: 702102,
    description:
      "The transaction was rejected because the amount is below the acceptable threshold.",
  },
  {
    code: 702103,
    description:
      "The transaction was rejected because the amount is above the acceptable threshold.",
  },
  {
    code: 702105,
    description:
      "The transaction timeout at the level of the Service Provider.",
  },
  {
    code: 702106,
    description:
      "Transaction failed. The Service Provider could not be reached temporarily (during the transaction initialization phase).",
  },
  {
    code: 703000,
    description:
      "Transaction failed due to a general business error. Contact support.",
  },
  {
    code: 703020,
    description:
      "The Service Provider could not be reached temporarily (after transaction initialization phase).",
  },
  {
    code: 703100,
    description:
      "Invalid input in request - one or more fields of the payment input request are invalid and were rejected.",
  },
  {
    code: 703102,
    description: "Service Provider has rejected the transaction.",
  },
  {
    code: 703103,
    description: "Recipient account with Service Provider is blocked.",
  },
  {
    code: 703104,
    description: "Sender account with Service Provider is blocked.",
  },
  {
    code: 703105,
    description:
      "Service Provider does not know the recipient (account) of the transaction.",
  },
  {
    code: 703106,
    description:
      "Service provider does not know the Sender/Initiator Account of the transaction.",
  },
  {
    code: 703107,
    description:
      "Recipient Account does not have sufficient funds with the Service Provider to perform this transaction.",
  },
  {
    code: 703108,
    description:
      "Initiator (sender) Account does not have sufficient funds with the Service Provider to perform this transaction.",
  },
  {
    code: 703109,
    description:
      "Service Provider rejected the transaction because the amount is below the threshold allowed.",
  },
  {
    code: 703110,
    description:
      "Service Provider rejected the transaction because the amount is above the threshold allowed.",
  },
  {
    code: 703111,
    description:
      "Sender/Initiator account has exceeded the limit (daily, weekly, monthly, etc.) with the Service Provider.",
  },
  {
    code: 703112,
    description:
      "Recipient account has exceeded the limit (daily, weekly, monthly, etc.) with the Service Provider.",
  },
  {
    code: 703113,
    description:
      "Payment Item (e.g. for bill payments) is no longer available for payment or has already been paid.",
  },
  {
    code: 703114,
    description:
      "The Service Provider has rejected the transaction amount. Invalid Amount in the transaction.",
  },
  {
    code: 703115,
    description:
      "The Service Provider does not support the Currency in the transaction.",
  },
  {
    code: 703117,
    description:
      "The Service Provider does not support the Account number in the transaction.",
  },
  {
    code: 703201,
    description:
      "Payment requires a customer confirmation to complete. Confirmation has not been given by the customer.",
  },
  {
    code: 703202,
    description:
      "Customer has rejected the payment / denied the approval of the transaction. The transaction cannot complete without the customer confirmation.",
  },
  {
    code: 703203,
    description:
      "Customer has provided Wrong/Invalid approval credentials which resulted in a rejection of the transaction by the Service Provider (e.g. wrong/invalid PIN, expired OTP, etc.). The transaction cannot complete without the customer confirmation.",
  },
  {
    code: 703503,
    description: "Service provider system is under maintenance.",
  },
  {
    code: 704000,
    description: "Technical error. Contact support.",
  },
  {
    code: 704003,
    description: "Payment processing error. Contact support.",
  },
  {
    code: 704004,
    description:
      "The time between searching for and paying for a payable item was too long and has expired. Please retry from the start.",
  },
  {
    code: 704006,
    description:
      "Unknown response provided by Service Provider. Contact Support.",
  },
  {
    code: 705000,
    description: "Unexpected technical error. Contact Support.",
  },
  {
    code: 705010,
    description:
      "Timeout during communication with the service provider. Contact Support.",
  },
  {
    code: 705020,
    description:
      "Timeout during communication with the service provider. Could not contact the service provider. Contact Support.",
  },
  {
    code: 705030,
    description:
      "Timeout during communication with the service provider. Could not contact the service provider. Contact Support.",
  },
  {
    code: 4000,
    description:
      "Authorization header is invalid - Check for proper s3pAuth Authorization header building",
  },
  {
    code: 4001,
    description: "Authorization header is missing",
  },
  {
    code: 4002,
    description: "One or more header parameters are incorrect",
  },
  {
    code: 4005,
    description: "Nonce already used",
  },
  {
    code: 4006,
    description: "Signature does not pass server validation",
  },
  {
    code: 4007,
    description: "Signature method not supported",
  },
  {
    code: 4008,
    description: "Timestamp is too old",
  },
  {
    code: 4009,
    description: "Access token invalid",
  },
  {
    code: 4101,
    description: "PTN is missing / Invalid",
  },
  {
    code: 4105,
    description: "One or more mandatory parameters are missing",
  },
  {
    code: 4106,
    description: "One or more mandatory parameters are empty",
  },
  {
    code: 4107,
    description:
      "Too many optional filter parameters were provided in the request",
  },
  {
    code: 4201,
    description:
      "Invalid service id. Please check the values that have been sent",
  },
  {
    code: 4202,
    description:
      "Invalid email address. Please check the values that have been sent",
  },
  {
    code: 4203,
    description:
      "Invalid merchant code. Please check the values that have been sent",
  },
  {
    code: 4204,
    description:
      "Invalid phone number. Please check the values that have been sent",
  },
  {
    code: 4205,
    description:
      "Invalid bill number. Please check the values that have been sent",
  },
  {
    code: 4207,
    description: "Invalid payment item id. Improper format",
  },
  {
    code: 4209,
    description: "Quote is invalid or expired",
  },
  {
    code: 4210,
    description: "Wrong service type for endpoint",
  },
  {
    code: 5000,
    description: "Unknown Server Error",
  },
  {
    code: 5010,
    description: "Unknown server error",
  },
  {
    code: 5011,
    description: "Service Unavailable",
  },
  {
    code: 40001,
    description: "HTTP Type not supported",
  },
  {
    code: 40002,
    description: "Internal service exception",
  },
  {
    code: 40003,
    description: "User token is not recognized",
  },
  {
    code: 40004,
    description: "User account does not have access to this API",
  },
  {
    code: 40005,
    description: "Internal server error",
  },
  {
    code: 40006,
    description: "Internal server error",
  },
  {
    code: 40007,
    description: "Internal server error",
  },
  {
    code: 40010,
    description: "Missing mandatory fields in request",
  },
  {
    code: 40011,
    description: "Error when requesting a search with more than 1 filter",
  },
  {
    code: 40013,
    description: "Request is invalid",
  },
  {
    code: 40014,
    description: "Company is not yet enabled",
  },
  {
    code: 40015,
    description: "User does not exist",
  },
  {
    code: 40201,
    description: "Insufficient account balance",
  },
  {
    code: 40202,
    description: "Collection limit exceeded",
  },
  {
    code: 40301,
    description: "Searched item could not be found",
  },
  {
    code: 40302,
    description:
      "One or more input parameters are invalid or contain invalid values",
  },
  {
    code: 40303,
    description: "Error during collection process",
  },
  {
    code: 40304,
    description: "Error with commissions",
  },
  {
    code: 40305,
    description: "Amount is too small for collections",
  },
  {
    code: 40401,
    description: "No free vouchers available. Contact Smobilpay support.",
  },
  {
    code: 40402,
    description: "Pricing error. Contact support.",
  },
  {
    code: 40403,
    description: "Endpoint does not support selected type",
  },
  {
    code: 40404,
    description: "The amount provided is not valid for this entity",
  },
  {
    code: 40406,
    description:
      "The amount provided is not valid for this entity. Partial payments are not allowed",
  },
  {
    code: 40407,
    description:
      "The amount provided is not valid for this entity. Overpayments are not allowed",
  },
  {
    code: 40408,
    description:
      "This service does not allow a precollection verification of the service number",
  },
  {
    code: 40409,
    description:
      "Payment amount outside of allowed range or proper denomination for this service",
  },
  {
    code: 40410,
    description:
      "Transfer of balance when company is in pooling mode is not allowed",
  },
  {
    code: 40602,
    description: "Service unknown",
  },
  {
    code: 40603,
    description: "Merchant unknown",
  },
  {
    code: 40604,
    description:
      "Payment record not found in system - wrong PTN or payment not done by requesting user",
  },
  {
    code: 40605,
    description: "Service type of service does not match requested type",
  },
  // Ajoutez le reste des messages d'erreur ici
];

// Créer une fonction utilitaire pour obtenir la description d'une erreur en fonction de son code
const getErrorMessage = (code: number): string | undefined => {
  const errorMessage = errorMessages.find((error) => error.code === code);
  return errorMessage ? errorMessage.description : "Code d'erreur non trouvé.";
};

export default getErrorMessage;
