import { t } from "i18next";
import useUser from "../../../hooks/useUser";
import calculateDaysRemaining from "../../../utils/calculateDaysRemaining";
import { Alert, AlertTitle } from "@mui/material";

const DueDateAlert = () => {
  const { user } = useUser();
  return (
    <>
      {user?.billService?.billDueDate &&
        calculateDaysRemaining(user.billService.billDueDate) < 6 && (
          <Alert severity="info">
            <AlertTitle>
              {t("due_message", {
                value: calculateDaysRemaining(user.billService.billDueDate),
              })}
            </AlertTitle>
            {t("due_message_description")}
          </Alert>
        )}
    </>
  );
};

export default DueDateAlert;
