import { Alert, Box } from "@mui/material";
import useDimensions from "../../../hooks/useDimensions";
import CenterContainer from "../../layout/container/centerContainer";
import BoxBillPayment from "../../../shared/boxBillPayment";
import DueDateAlert from "../../layout/dueDateAlert/dueDateAlert";
import ReviewAlert from "../reviewAlert/reviewAlert";

const StepFormThree = ({ service, selectedPackage }: any) => {
  const { isSmall } = useDimensions();
  const display = isSmall ? "block" : "flex";
  const paddingX = isSmall ? 4 : 0;

  return (
    <CenterContainer>
      <DueDateAlert />
      <ReviewAlert />
      <Box
        sx={{
          display,
          justifyContent: "center",
          mt: 2,
          marginBottom: 5,
          paddingX,
          paddingBottom: 5,
        }}
      >
        <BoxBillPayment service={service} />
      </Box>
    </CenterContainer>
  );
};

export default StepFormThree;
