import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { ReactNode } from "react";

interface Option {
  value: string;
  label: string;
}

interface PaymentMethodSelectProps {
  id: string;
  value: string;
  label: string;
  fullWidth?: boolean;
  children: any;
  onChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
}

const SelectInput: React.FC<PaymentMethodSelectProps> = ({
  id,
  value,
  label,
  fullWidth = true,
  children,
  onChange,
}) => {
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={`${id}-select`}>{label}</InputLabel>
      <Select
        id={id}
        value={value}
        label={label}
        onChange={onChange}
        fullWidth={fullWidth}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
