import { useMediaQuery, Theme } from "@mui/material";

function useDimensions() {
  const isSmall = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.down("sm"),
    { noSsr: true }
  );

  const isMedium = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"), {
    noSsr: true,
  });

  return {
    isSmall,
    isMedium,
  };
}

export default useDimensions;
