const calculateDaysRemaining = (dueDate: string) => {
  // Récupérer la date actuelle
  const currentDate = new Date();
  const due = new Date(dueDate);
  const dueDateTimestamp = due.getTime();
  const currentTimestamp = currentDate.getTime();

  // Calculer la différence en millisecondes
  const timeDifference = dueDateTimestamp - currentTimestamp;
  // Calculer le nombre de millisecondes entre la date d'échéance et la date actuelle

  // Convertir les millisecondes en jours
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysRemaining;
};

export default calculateDaysRemaining;
