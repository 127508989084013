import React from "react";

import getButtonWidth from "../../../utils/getButtonWidth";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  Typography,
  SxProps,
} from "@mui/material";

export interface ButtonProps extends MuiButtonProps {
  customWidth?: "normal" | "large" | "fullWidth";
  isLoading?: boolean;
  loadingMessage?: string;
  minWidth?: string;
  variant?: "text" | "contained" | "outlined";
  label?: string;
  children?: React.ReactNode;
  sx?: SxProps;
}

const Button: React.FC<ButtonProps> = ({
  isLoading = false,
  loadingMessage,
  minWidth = "150px",
  customWidth = "normal",
  children,
  label,
  sx,
  variant = "contained",

  ...otherProps
}) => {
  const renderButtonContent = () => {
    if (isLoading) {
      return (
        <>
          <CircularProgress
            size={20}
            color="inherit"
            style={{ marginRight: 10 }}
          />
          {loadingMessage && <Typography>{loadingMessage}</Typography>}
        </>
      );
    }
    return children || label;
  };

  return (
    <MuiButton
      variant={variant}
      sx={{
        minWidth: getButtonWidth(customWidth),
        ...sx,
      }}
      {...otherProps}
    >
      {renderButtonContent()}
    </MuiButton>
  );
};

export default Button;
