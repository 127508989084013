import Nav from "./routes/nav";
import Embed from "./routes/embed";
import { useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();
  const isEmbeddedApp = location.pathname.includes("/embed");

  if(isEmbeddedApp) return <Embed/>

  return  <Nav />;
};

export default App;
