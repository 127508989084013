import { ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Route, Routes } from "react-router-dom";
import theme from "../config/theme";
import EmbedPage from "../pages/embed";
import Service from "../pages/service";
import StatusPage from "../pages/status";
import VerifyPage from "../pages/verify";
import { UserProvider } from "../provider/userProvider";
import { useQueryProduct } from "../api/queries";

const useStyles = makeStyles((theme) => ({
  fullScreen: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

const Embed = () => {
  const classes = useStyles();

  useQueryProduct();

  return (
    <div className={classes.fullScreen}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <Routes>
            <Route path="/embed" element={<EmbedPage />}>
              <Route index element={<EmbedPage />} />
            </Route>
            <Route path="/embed/service/:id" element={<Service />} />
            <Route path="/embed/verify/:id" element={<VerifyPage />} />
            <Route path="/embed/verify/:id/:canalId" element={<StatusPage />} />
          </Routes>
        </UserProvider>
      </ThemeProvider>
    </div>
  );
};

export default Embed;
