import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Typography } from "@mui/material";
import useDimensions from "../../../hooks/useDimensions";
import Card from "../../ui/card";
import { t } from "i18next";

const ErrorCard = ({
  accountNumber,
  amount,
  errorMessage = "an error Occured!",
}: {
  accountNumber: number;
  amount: number;
  errorMessage?: string;
}) => {
  const { isSmall } = useDimensions();
  const maxWidth = isSmall ? "unset" : "38%";

  return (
    <Box sx={{ maxWidth }}>
      <Card
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          padding: isSmall ? 5 : 10,
        }}
      >
        <Box>
          <Box display={"flex"} justifyContent={"center"}>
            <CancelIcon color="error" sx={{ fontSize: 70 }} />
          </Box>

          <Typography
            fontSize={32}
            fontWeight={600}
            textAlign={"center"}
            color={"#D92D20"}
            paddingBottom={5}
          >
            {t("stepper_4.failed_transaction")}
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{ fontWeight: "400", fontSize: 19, color: "#707070" }}
            paddingBottom={5}
          >
            {t("stepper_4.payment_id")} {accountNumber} {t("stepper_4.failed")} 
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{
              fontWeight: "400",
              fontSize: 19,
              color: "#707070",
              paddingBottom: 5,
            }}
          >
            {errorMessage}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default ErrorCard;
