import { paymentMethods } from "../mockapi/getPaymentMethods";
import { PaymentMethod } from "../models/PaymentMethod";
import getSystemLanguage from "./getSystemLanguage";

const getInstructionByPaymentProvider = (code: string | undefined): string => {
  const userLanguage = getSystemLanguage();
  const message = paymentMethods.find((item) => item.payItemId === code);
  const element = message?.instructions.find((el) => el.lang === userLanguage);

  return element ? element.text : "";
};

export const getProviderByPaymentProvider = (
  code: string | undefined
): PaymentMethod | undefined => {
  const provider = paymentMethods.find((item) => item.payItemId === code);

  return provider;
};

export default getInstructionByPaymentProvider;
