import { ChipProps, Chip as MUIChip, colors } from "@mui/material";
import { makeStyles } from "@mui/styles";
import getColorByStatus from "../../../utils/getColorByStatus";

interface Props extends ChipProps {
  label: string;
  status: string | boolean;
  variant?: "outlined" | "filled";
}

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: colors.green[50],
    color: colors.green[500],
  },

  warning: {
    backgroundColor: colors.yellow[50],
    color: "#F7B217",
  },

  neutral: {
    background: colors.brown[50],
    color: colors.brown[500],
  },
  error: {
    backgroundColor: colors.red[50],
    color: colors.red[500],
  },
}));

const Chip = ({ label, status, variant = "filled", sx, ...rest }: Props) => {
  const classes = useStyles();

  const renderClass = () => {
    switch (status?.toString().toUpperCase()) {
      case "SUCCESS":
      case "APPROVED":
      case "COMPLETED":
      case "PAID":
      case "TRUE":
        return classes.success;
      case "FAILED":
      case "FAILURE":
      case "ERRORED":
      case "FALSE":
      case "REJECTED":
        return classes.error;
      case "PENDING":
      case "NOTPAID":
      case "DEBITED":
      case "INPROCESS":
      case "UNDERINVESTIGATION":
      case "ERROREDREFUNDED":
        return classes.warning;
      case "CREATED":
      case "OPEN":
      case "NEW":
        return classes.neutral;
      default:
        return classes.neutral;
    }
  };
  return (
    <MUIChip
      sx={{ borderRadius: 1, paddindX: 2, fontWeight: "bold", ...sx }}
      label={label}
      color={getColorByStatus(status)}
      variant={variant}
      className={renderClass()}
      data-testid="chip-testid"
      {...rest}
    />
  );
};

export default Chip;
