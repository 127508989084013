import config from "../config";

export default function fetchApi(endpoint?: string) {
  const apiHost = config.REACT_APP_PROXY_BASE_URL;
  //const apiHost = "https://webpayproxy.smobilpay.acceptance.maviance.info";

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return {
    get: async <T>(params?: any): Promise<T> => {
      const data = await fetch(`${apiHost}/${endpoint}${params || ""}`, {
        method: "GET",
        headers,
      });

      return data.json();
    },
    post: async (params?: any) => {
      const data = await fetch(`${apiHost}/${endpoint}`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      });

      return data.json();
    },
  };
}
