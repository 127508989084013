import { Box, Typography, colors } from "@mui/material";
import { Service } from "../../../models/Service";
import useDimensions from "../../../hooks/useDimensions";
import getLogoByService from "../../../utils/getLogoByService";

interface HeaderCardServiceProps {
  service: Service;
}

const HeaderCardService = ({ service }: HeaderCardServiceProps) => {
  const { isSmall } = useDimensions();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: isSmall ? "100%" : 388,
      }}
    >
      <Box
        sx={{
          marginLeft: !isSmall ? 0 : 1,
          marginRight: isSmall ? 2 : 4,
          minWidth: 100,
          maxWidth: 100,
        }}
      >
        <img
          data-testid="service-image"
          src={`/assets/${getLogoByService(service.serviceid as string)}`}
          loading="lazy"
          alt="service logo "
          width="100%"
        />
      </Box>
      <Box width={"100%"}>
        <>
          <Typography
            data-testid="service-title"
            sx={{ fontWeight: "bold", fontSize: 26 }}
          >
            {service?.title}
          </Typography>
          <Typography
            data-testid="service-description"
            sx={{
              fontWeight: "bold",
              fontSize: 12,
              color: colors.grey[600],
            }}
          >
            {service?.description}
          </Typography>
        </>
      </Box>
    </Box>
  );
};

export default HeaderCardService;
