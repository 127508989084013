import { Box, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const footerItems = [
    {
      title: t("footer.terms"),
      link: "https://corporate.maviance.cm/mentions-legales",
    },
    {
      title: t("footer.partners"),
      link: "https://corporate.maviance.cm/partenaires",
    },
    {
      title: t("footer.privacy"),
      link: "https://maviance.cm/confidentialite/",
    },
  ];

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 25,
      }}
    >
      <Box sx={{ psoition: "relative", width: "100vw" }}>
        <Typography color="white" textAlign={"center"}>
          {t("footer.description")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100vw",
          justifyContent: "center",
        }}
      >
        {footerItems.map((items, _index) => (
          <Typography color="white" px={1}>
            {_index > 0 && "· "}
            <Link href={items.link} color="inherit">
              {items.title}
            </Link>
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default Footer;
