import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import useDimensions from "../../../hooks/useDimensions";

interface SectionProps {
  title: string;
  children?: ReactNode;
}
const Section = ({ title, children }: SectionProps) => {
  const { isSmall } = useDimensions();

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          py: isSmall ? 2 : 5, // Utilisation de l'opérateur ternaire
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: isSmall ? 16 : 20, // Utilisation de l'opérateur ternaire
            textAlign: "left",
          }}
        >
          {title}
        </Typography>
      </Box>

      {children}
    </div>
  );
};

export default Section;
