import { Box, BoxProps, TextField, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";

interface SearchBarProps {
  onChange?: any;
  placeHolder: string;
  focused?: boolean;
  onFocus?: () => void;
}

const SearchBar = ({
  placeHolder,
  focused = false,
  onFocus = () => null,
  onChange = () => null,
  ...rest
}: SearchBarProps & BoxProps) => {
  const { palette } = useTheme();
  // Create a ref for the input element
  const [autoFocus, setAutoFocus] = useState(focused);

  // Function to focus on the input
  const focusInput = () => {
    setAutoFocus(true);
  };

  return (
    <Box {...rest}>
      <TextField
        onInput={(e) => {}}
        onChange={onChange}
        variant="outlined"
        placeholder={placeHolder}
        size="medium"
        fullWidth
        autoFocus={autoFocus}
        focused={focused}
        onFocus={onFocus}
      />
      <IconButton
        type="submit"
        aria-label="search"
        edge={false}
        sx={{ backgroundColor: palette.primary.main, minWidth: "48px" }}
        style={{ borderRadius: 0 }}
        onClick={() => {
          onFocus();
          focusInput();
        }}
        data-testid="SearchIcon"
      >
        <SearchIcon style={{ fill: "white" }} />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
